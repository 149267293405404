.MuiFormControl-root {
  width: 100% ;
  border-radius: 0.125rem !important;
  border: 1px solid #C4C4C4 !important;

  .MuiInputBase-adornedEnd.MuiInputBase-formControl
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    padding-left: 15px !important;
    width: 100%;
  }

  div.ui.label.dropdown[role='listbox'],
  div:not(.select__input) > input[type='number'],
  div:not(.select__input) > input[type='text'],
  .select__control {
    border: 0 !important;
  }
}
