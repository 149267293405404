#app {
  .announcementDetail__content {
    font-family: @primary-font;
    padding: 2rem 18rem 6rem 18rem;
    @media screen and (max-width: @bp-3xl-mx) {
      padding-left: 10rem;
      padding-right: 10rem;
    }
    @media screen and (max-width: @bp-xmd-mx) {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    @media screen and (max-width: @bp-xsm-mx) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @media (max-width: @bp-md-mx) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .announcementHeader {
      position: relative;
      h1 {
        color: @c-primary;
        font-size: 3rem;
        line-height: 1.2em;
        margin-bottom: 3rem;

        @media (max-width: @bp-md-mx) {
          font-size: @font-32;
          margin-bottom: 0;
        }
      }
      img.ui.image {
        width: 90px;
        height: 90px;
        position: absolute;
        top: -10px;
        right: -40px;
      }
    }

    .announcementDetailSectionBody {
      .announcementCardsParagraph + .announcementDetailParagraph {
        margin: 4rem 0 0 0;
      }
      .announcementDetailParagraph {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 auto;
        z-index: 900;

        & > * {
          z-index: inherit;
        }

        .p-title {
          border-bottom: 1px solid @c-grey-light;
          width: 100%;
          color: @c-grey-dark;
          font-size: @font-32;
          margin-bottom: 0.7rem;
          padding-bottom: 0.5rem;

          @media (max-width: @bp-md-mx) {
            font-size: @font-24;
          }

          h1,
          h2 {
            font-family: @primary-font;
            font-weight: 600;
          }
          h1 {
            @media (max-width: @bp-md-mx) {
              font-size: @font-24;
            }
          }
        }
        .row {
          border-bottom: 1px solid @c-grey-light;
          width: 100%;
          &.no__border {
            border-bottom: none;
            font-size: @font-18;
            margin-top: 1rem;
          }
          .row-title {
            font-size: 14px;
            margin-top: 2rem;
            margin-bottom: 0.7rem;
            color: @c-primary;
            font-weight: bold;
          }
          .row-content {
            font-family: @primary-font;
            margin-bottom: 2rem;

            a {
              color: @c-secondary;
              text-decoration: underline;
            }
          }
          &.attachments {
            display: flex;
            flex-direction: column;
            border-bottom: none;
            .fileItem {
              position: relative;
              border-radius: 4px;
              border: 1px solid @c-grey-light;
              padding: 1rem 2rem;
              flex: 0 0 5rem;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-content: center;
              max-height: 5rem;
              &:not(:first-child) {
                margin-top: 1rem;
              }
              > * {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              > i.icon.file.alternate {
                flex: 0 0 2rem;
                font-size: 2rem;
                color: #8f8f8f;
                margin: 0 15px 0 0;
              }
              .file--info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                flex: 1;
                > .fileName,
                .fileExtension {
                  flex: 0 0 fit-content;
                  flex: 1;
                  line-height: 1.3em;
                  justify-content: flex-start;
                  margin-left: 0;
                  margin-right: auto;
                  @media screen and (max-width: @bp-lg-mx) {
                    flex: 0 0 fit-content;
                    display: flex;
                  }
                }
                > .fileName {
                  font-weight: 600;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1; /* number of lines to show */
                  line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                > .fileExtension {
                  text-transform: uppercase;
                  font-size: @font-14;
                  line-height: 21px;
                  bottom: 20px;
                  margin-top: 2px;
                  @media screen and (max-width: @bp-lg-mx) {
                    margin-top: 7px;
                  }
                }
              }
              > .ui.button {
                background: transparent;
                flex: 0 0 2rem;
                width: 2rem;
                i.icon {
                  font-size: 1.5rem;
                  color: @c-secondary;
                  height: auto;
                }
                &:not(:first-child) {
                  margin-left: 10px;
                }
              }
              .ui.loader {
                flex: 0 0 2rem;
                position: relative;
                left: auto;
                top: 0;
                right: 0;
                transform: unset;
                display: flex;
                align-items: center;
                justify-content: center;
                &::before,
                &::after {
                  position: relative;
                }
                &::after {
                  left: 0;
                }
              }
              &:hover {
                box-shadow: 6px 4px 24px rgba(131, 146, 161, 0.24);
              }
              @media screen and (max-width: @bp-lg-mx) {
                flex: 0 0 5.5rem;
                max-height: 5.5rem;
                align-content: center;
              }
              @media screen and (max-width: @bp-2xmd-mx) {
                padding: 0.8rem 1.5rem;
              }
            }
          }
          &.call-to-action {
            margin-top: 1rem;
          }
        }
      }
      .announcementCardsParagraph {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .ui.cards {
          width: 100%;
          justify-content: center;
          flex-wrap: nowrap;
          margin: 0 -0.5rem;

          @media (max-width: @bp-md-mx) {
            flex-wrap: wrap;
            margin: 0;
          }
        }

        .ui.card {
          box-shadow: none;
          color: @c-grey-dark;
          border-radius: 8px;
          padding: 1.5rem;
          transition: box-shadow 0.3s ease-out;
          box-shadow: 6px 4px 24px rgba(131, 146, 161, 0.24);

          @media (max-width: @bp-md-mx) {
            width: calc(50% - 1rem);
          }

          > div {
            line-height: 1.2;
          }

          strong {
            color: @c-primary;
            font-size: @font-14;
            display: inline-block;
            margin-bottom: 0.5rem;
          }

          > .content {
            border-top: none;
            position: relative;
            display: flex;
            flex-direction: column;

            &:not(:first-child) {
              &::before {
                content: "";
                position: absolute;
                background: @c-grey-light;
                height: 1px;
                top: 0;
                left: 5%;
                width: 90%;
              }
            }

            .header {
              > a {
                color: @c-primary;
                font-weight: bold;
                font-size: 18px;
                line-height: 1.32em;
              }
            }
            .sub.header {
              text-transform: none;
              font-size: 14px;
              line-height: 17px;
              font-weight: bold;
            }
            .ui.sub.content {
              font-family: @primary-font;
              font-size: 16px;
              line-height: 1.4em;
              margin-top: 5px;
            }
            .description {
              font-family: @primary-font;
              font-size: 14px;
              line-height: 1.4em;
              color: @c-grey-norm;
              margin-bottom: 2rem;
            }
            .cta {
              align-self: flex-end;
              > a {
                color: @c-secondary;
                font-family: @primary-font;
                font-weight: bold;
                text-decoration: underline;
              }
              > i.icon {
                color: @c-secondary;
              }
            }
          }
        }
      }
      .otherInfoAndContacts {
        margin-top: 4rem;
        .announcementDetailParagraph {
          &:not(:first-child) {
            margin: 2.5rem 0 0 0;
          }
          > .row {
            border-bottom: none;
          }
        }
      }
    }
  }
}
