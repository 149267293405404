#app {
  .announcementEdit__content {
    font-family: @primary-font;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .announcementEditSectionBody {
      padding: 2rem;
      max-width: 1700px;
      width: 100%;
      font-weight: 400;
      font-size: 1rem;
      .ui.form input[type="text"] {
        font-family: @primary-font;
      }
      .form__AnnouncementEdit {
        position: relative;
        width: 100%;
        > .p-title {
          position: relative;
          width: 100%;
          padding: 1rem;
          margin-bottom: 2rem;
          &::after {
            content: "";
            width: calc(100% - 2rem);
            height: 100%;
            position: absolute;
            top: 0;
            left: 1rem;
            border-bottom: 1px solid @c-grey-light;
          }
          > h1 {
            font-family: @primary-font;
          }
        }
        .row + .p-title {
          margin-top: 3rem;
        }
        .row {
          margin-bottom: 2rem;
          width: 100%;
          display: flex;
          .column {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
            .MuiFormControl-root.MuiTextField-root {
              .MuiButtonBase-root.MuiIconButton-root {
                color: @c-secondary;
              }
            }
            label {
              font-size: @font-14;
              line-height: 17px;
              font-family: @primary-font;
              margin-bottom: 3px;
              padding-left: 0.5rem;
            }
            div {
              .field {
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 20px 0 0 0;
                div[role="combobox"],
                div[role="listbox"] {
                  margin: 0 0 0.35rem;
                }
                label {
                  color: @c-grey-mid;
                  font-size: 1rem;
                  line-height: 1.214em;
                  position: absolute;
                  z-index: 2;
                  top: 2rem;
                  left: 1rem;
                  margin: 0 0 0 0;
                  width: calc(100% - 1.3rem);
                  pointer-events: none;
                  transition: all 0.3s ease-out;
                  padding-left: 0;
                }
                .selection.dropdown:not([role="listbox"]) {
                  padding: 0.19345238em 1.975em 0.19345238em 0.3125em;
                }
              }
              &.filled {
                .field {
                  label {
                    top: 0;
                    left: 0.5rem;
                    color: @c-grey-dark;
                    font-size: @font-14;
                    line-height: 17px;
                  }
                  &.error {
                    label {
                      color: @c-error;
                    }
                  }
                }
              }
            }
            textarea {
              font-family: @primary-font;
              font-size: 1rem;
              margin-bottom: 0.35rem;
              & ~ span {
                font-size: @font-12;
                color: @c-error;
              }
            }
          }
        }
        .one.column.row {
          > .column {
            flex: 0 0 100%;
          }
          div#editor {
            width: 100%;
            .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
              color: @c-grey-mid;
            }
            .ck.ck-editor__main > .ck-editor__editable.ck-focused {
              color: @c-grey-dark;
            }
            .ck.ck-editor {
              .ck.ck-editor__main {
                .ck-editor__editable {
                  width: 100%;
                  max-width: 100%;
                  height: 9rem;
                  font-size: 1rem;
                  line-height: 1.5em;
                  word-break: break-word;
                }
              }
            }
            &.taller {
              .ck.ck-editor {
                .ck.ck-editor__main {
                  .ck-editor__editable {
                    height: 18rem;
                  }
                }
              }
            }
          }
          .dropzone-area {
            padding: 1.5rem 3rem;
            border: 1px dashed @c-grey-light;
            border-radius: 4px;
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            .left-side {
              display: flex;
              align-items: center;
              i.icon {
                font-size: 1.5rem;
                color: @c-secondary;
                margin-right: 13px;
              }
              .upload--text {
                p {
                  font-size: 18px;
                  line-height: 20px;
                  margin: 0;
                  &:last-child {
                    font-size: @font-14;
                    color: @c-grey-mid;
                    margin-top: 3px;
                  }
                }
              }
            }
            button.ui.button--primary--negative {
              border-radius: 4px;
              padding: 15px 1.5rem !important;
            }
          }
          .upload--error {
            font-size: @font-12;
            margin-left: 1rem;
            color: @c-error;
          }
          input[type="file"] {
            width: 100%;
          }
          .toBeUploaded,
          .alreadyUploaded {
            margin-top: 20px;
            h3 {
              font-family: @primary-font;
              font-weight: 1rem;
            }
            .upload__list {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
            }
            .upload__item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 6px 1rem;
              background: @c-background;
              border-radius: 5px;
              width: 98%;
              flex: 0 0 2.5rem;
              &:not(:first-child) {
                margin-top: 5px;
              }
              h4 {
                margin: 0;
                font-size: 1rem;
                line-height: 19px;
                font-family: @primary-font;
                font-weight: normal;
                display: flex;
                align-items: inherit;
              }
              .upload__left,
              .upload__right {
                display: flex;
                align-items: inherit;
              }
              .upload__left {
                i.icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 0;
                  padding: 0;
                  position: relative;
                  line-height: 1em;
                  &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 0;
                    margin: 0;
                  }
                }
              }
            }
            .upload__right {
              .upload__delete {
                width: 1rem;
                height: 1rem;
                margin-left: 10px;
                position: relative;
                cursor: pointer;
                &::after,
                &::before {
                  content: "";
                  position: absolute;
                  width: 2px;
                  height: 100%;
                  background: @c-secondary;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  border-radius: 1px;
                }
                &::after {
                  transform: rotate(135deg);
                }
                &::before {
                  transform: rotate(45deg);
                }
              }
              .upload__dowmload,
              .upload__preview {
                margin-left: 10px;
                position: relative;
                cursor: pointer;
                font-size: 0.9rem;
                color: @c-secondary;
                > i.icon {
                  margin: 0;
                }
              }
            }
          }
          .alreadyUploaded {
            .upload__right {
              .upload__remove {
                margin-left: 10px;
                position: relative;
                cursor: pointer;
                font-size: 0.9rem;
                color: @c-secondary;
              }
            }
          }
        }
        .two.column.row {
          > .column {
            flex: 0 0 calc(100% / 2);
          }
        }
        .three.column.row {
          > .column {
            flex: 0 0 calc(100% / 3);
          }
        }
        .row.call-to-action {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .column {
            &:first-child {
              display: flex;
              flex-direction: row;
            }
          }
          button {
            > i.icon {
              margin-right: 8px !important;
            }
          }
        }
      }
      .row.archive {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;
        .ui.button {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
    .reloader {
      position: relative;
      width: 100%;
      min-height: calc(100vh - (@height-topbar + @height-footer));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .invalid-field {
    border: 1px solid #E0B4B4;
    background-color: #FFF6F6;
  }

  .invalid-field-error {
    color: #eb5757;
  }

  .invalid-field-label {
    color: #9f3a38;
  }
}

.MuiDialog-root {
  .MuiButton-textPrimary {
    &.button--confirm {
      position: relative;
      padding: 0.5rem 0.8rem;
      border-radius: 4px;
      border: 1px solid;
      z-index: 10;
      font-weight: 600;
      font-family: @primary-font;
      transition: all 0.3s linear;
      &.yes {
        border-color: @c-success;
        color: @c-success;
      }
      &.no {
        border-color: @c-error;
        color: @c-error;
      }
      &:last-child {
        margin-left: 15px;
      }
      &:hover {
        transition: all 0.3s linear;
        color: @c-white;
        &.yes {
          background: @c-success;
        }
        &.no {
          background: @c-error;
        }
      }
    }
  }
  .css-hlj6pa-MuiDialogActions-root {
    padding: 1rem 24px;
  }
  .MuiPickersDay-daySelected {
    background: @c-secondary !important;

    &:hover {
      background: @c-primary !important;
      color: @c-white !important;
    }
  }
  .MuiPickersDay-current {
    color: @c-primary !important;
  }
}
