#app {
  .elencoBandi__content {
    .ui.dividing.header.elencoBandiHeader {
      margin: 0;
      padding: 1rem 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: @bp-md-mx) {
        flex-direction: column;
      }

      .ui.form {
        margin: 0;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid @c-grey-light;
      }

      @media screen and (max-width: @bp-md-mx) {
        padding: 1rem;
      }

      .totalItems {
        > h4 {
          display: flex;
          font-family: @primary-font;
          font-size: 1rem;
          line-height: 1.2em;
          font-weight: 400;

          @media (max-width: @bp-md-mx) {
            padding-bottom: 1rem;
          }
          > span {
            font-weight: 700;
            color: @c-primary;
          }
        }
      }

      > * {
        &:nth-child(2) {
          background: transparent;
          margin-left: auto;
          margin-right: 20px;
          display: flex;
          position: relative;
          .ui.button.floating.labeled.dropdown {
            border-radius: 4px;
            padding: 15px 2rem;
            margin: 0;
            background: transparent;
            color: @c-primary;
            font-size: 1rem;
            font-weight: 600;
            z-index: 1;
            >.divider.text {
              margin-right: 15px;
            }
            > i.icon {
              background: transparent;
              left: 10px;
              color: @c-secondary;
              font-size: 1.2rem;
            }
          }
          i.icon {
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0;
            top: 0;
            right: 7px;
            background: transparent;
            color: @c-primary;
            font-size: 1rem;
          }
        }
      }

      .ui.button.labeled.dropdown {
        display: flex;
        height: 100%;
        font-family: @primary-font;
        font-size: 1rem;
        margin: 0;
      }

      .ui.selection.dropdown {
        padding: 10px 20px;
        font-size: 1rem;
        width: 21em;
        font-family: @primary-font;
        line-height: 1.5em;
        display: flex;
        z-index: 3;

        > div {
          color: @c-grey-mid;
        }

        > .dropdown.icon {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      .row {
        padding-top: 0;
        padding-bottom: 0;
      }

      .ui.grid {
        margin: 0;
        align-items: center;

        .column {
          padding: 0;

          @media screen and (max-width: @bp-md-mx) {
            width: 100% !important;
            padding-bottom: 1rem;
          }
        }
      }

      h1 {
        font-size: @font-32;
        font-weight: 500;
        margin-right: 2rem;

        @media screen and (max-width: @bp-md-mx) {
          font-size: @font-28;
        }
      }

      .padded.grid {
        .row {
          padding: 0;
          align-items: center;

          .field {
            margin: 0 1.5rem 0 0;
            display: flex;
          }
        }

        .elencoBandi__buttons {
          display: flex;

          > .button--primary {
            margin-right: 1.5rem;
          }
        }
        .new_elencoBandi__buttons {
          display: flex;
          margin-left: 0;
          > .button--primary {
            margin-right: 1.5rem;
          }
        }
      }
    }

    .elencoBandiSectionBody {
      max-width: 90rem;
      margin: 0 auto;
      padding: 2.5rem 0;

      @media (max-width: @bp-2xl-mx) {
        max-width: 70rem;
      }

      @media screen and (max-width: @bp-md-mx) {
        padding: 2.5rem 1rem 4rem;
      }

      .ui.pagination.menu {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
      }
    }

    .elencoBandiSectionActions {
      padding-top: 1.5rem;
      float: right;
    }
  }
}
