div#app {
  height: 100vh;
  width: 100vw;
  font-family: @secondary-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: @secondary-font;
  }
}

* {
  box-sizing: border-box;
}

.fluid {
  width: 100%;
  height: auto;
}

.fluid-y {
  height: 100%;
}

.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pointer {
  cursor: pointer;
}

h2 {
  font-size: @font-24;
  line-height: 1.1em;
  font-weight: bold;
}
