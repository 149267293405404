form.ui.form .field,
form {
  margin: 0 0 0.35rem;

  // Label
  label {
    font-size: @font-14;
    font-weight: 400;
    font-family: @primary-font;
    //margin-top: 0.5rem;

    a {
      color: @c-primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  input,
  textarea,
  .selection.dropdown {
    border-color: @c-grey-light;
    border-radius: 0.125rem;
    padding: 0.65rem 1rem;
    font-weight: 500;
    font-family: @primary-font;
    width:100%;

    &::placeholder {
      color: @c-grey-mid;
    }
  }

  .selection.dropdown {
    width: 100%;

    .text {
      line-height: initial;
    }
  }

  .orderBy {
    min-width: 285px;
  }
  
  .userType {
    min-width: 335px;
  }

  // Checkbox
  .ui.checkbox:not(.toggle) {
    input {
      height: 1.5rem;
      width: 1.5rem;
    }

    label {
      text-align: left;
      margin: 0;
      padding-left: 2.5rem;

      &:before {
        height: 1.5rem;
        width: 1.5rem;
        border-color: @c-grey-mid;
        // top: -.25rem;
      }
    }

    &.checked {
      label:before {
        background: @c-success;
        border-color: @c-success;
      }

      label:after {
        content: "";
        height: 1.5rem;
        width: 1.5rem;
        // background: url('../../images/icon/done.svg') no-repeat center center;
        background-size: 80%;
      }
    }
  }

  // Toggle
  .ui.checkbox.toggle {
    label:before {
      width: 4rem;
      height: 2rem;
      top: -0.25rem;
    }

    label:after {
      width: 1.625rem;
      height: 1.625rem;
      box-shadow: none;
      background: @c-white;
      top: -0.05rem;
      left: 0.2rem;
    }

    input:checked ~ label:before {
      background-color: @c-success !important;
    }

    input:checked ~ label:after {
      left: 2.2rem;
    }
  }

  // Message
  & + span {
    font-size: @font-12;
  }

  &.error + span {
    color: @c-error;
  }

  div ~ span {
    font-size: @font-12;
    margin-top: 4px;
  }

  .error ~ span {
    color: @c-error;
  }
}

// Filter select tags
div[role="combobox"],
.ui.multiple.dropdown {
  display: flex;
  flex-wrap: wrap;
  > .ui.label {
    background-color: @c-primary;
    color: @c-white;
    font-family: @primary-font;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.8rem 0.5rem 1.5rem;
    width: fit-content;
    > i {
      color: @c-primary;
      flex: 0 0 25px;
      height: 25px;
      position: relative;
      padding: 10px;
      background: @c-white;
      opacity: 1;
      border-radius: 50%;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
