#app {
  .standardlayout__container {
    width: 100%;
    min-height: 100vh;
    padding: @height-topbar 0 0 5rem;
    background: @c-white no-repeat center center;
    background-size: cover;
    padding-top: @height-topbar;
    position: relative;

    @media screen and (max-width: @bp-xmd-mx) {
      padding: 6.25rem 0 0 3.25rem;
    }
  }
  .MuiBreadcrumbs-root {
    position: relative;
    padding-top: 1rem;
    padding-left: 2rem;
    > ol {
      font-weight: 500;
      font-size: 15px;
      .MuiBreadcrumbs-li a {
        color: @c-primary;
        position: relative;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          background: transparent;
          width: 0;
          height: 100%;
          border-bottom: 1px solid @c-primary;
        }
        &:hover {
          text-decoration: none;
          &::before {
            width: 100%;
            transition: width 0.3s linear;
          }
        }
      }
      li.MuiBreadcrumbs-li {
        color: @c-grey-mid;
      }
    }
  }
  div.scroll__container {
    min-height: calc(100vh - (@height-topbar + @height-footer));
  }
}
