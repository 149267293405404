:root.bancavalsabbina {
  --secondary-font: "pt-sans", sans-serif;
  --primary-font: "Arial", "Lucida Grande", sans-serif;
  --standard-font: "Arial", "Lucida Grande", sans-serif;

  --min-sidebar: 22rem;
  --max-sidebar: 30rem;
  --width-sidebar: 16rem;
  --width-filterSidebar: 25rem;
  --height-topbar: 6.25rem;
  --height-footer: 146px;
  --checkbox-size: 1.375rem;
  --result-checkbox-size: 1.5rem;

  --font-36: 2.25rem;
  --font-32: 2rem;
  --font-28: 1.75rem;
  --font-24: 1.5rem;
  --font-22: 1.375rem;
  --font-20: 1.25rem;
  --font-18: 1.125rem;
  --font-16: 1rem;
  --font-14: 0.875rem;
  --font-12: 0.75rem;

  --gradient-deg: 270deg;

  --c-primary: #000;
  --c-primary-rgb: 0, 0, 0;
  --c-primary-light: #454545;
  --c-secondary: #56bc60;
  --c-secondary-rgb: 86, 188, 96;
  --c-secondary-dark: #56bc60;
  --c-secondary-light: #56a6d2;
  --c-background: #f8f8f8;
  --c-footer-background: #fff;
  --c-footer: #000;
  --c-footer-border: #d1d3d4;

  --c-grey-light: #dae0e7;
  --c-grey-button: #ebebeb;
  --c-grey-background: #e5e5e5;
  --c-grey-mid: #9c9c9c;
  --c-grey-dark: #1d1d1d;
  --c-black-light: #262626;
  --c-white: #ffffff;

  --c-success: #239f76;
  --c-error: #eb5757;

  --bp-xsm-mx: 35rem;
  --bp-sm-mx: 40rem;
  --bp-md-mx: 48rem;
  --bp-xmd-mx: 52rem;
  --bp-2xmd-mx: 61rem;
  --bp-lg-mx: 64rem;
  --bp-xl-mx: 80rem;
  --bp-2xl-mx: 105rem;
  --bp-3xl-mx: 120rem;
}
