body.customer-list {
  table.ui.table {
    border-collapse: separate;
    border-spacing: 0 .5rem;

    thead {
      display: none;
    }

    tbody td {
      border: 0;

      &:first-child {
        text-align: left;
        color: @c-primary;
        font-size: @font-24;
        font-weight: 700;
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
      }

      &:last-child {
        text-align: right;
        font-size: @font-14;
        font-weight: 600;
        border-top-right-radius: .5rem;
        border-bottom-right-radius: .5rem;

        a {
          text-decoration: underline;
          color: #000;
        }
      }
    }
  }
}