body.list {
  table.ui.table {
    border-collapse: separate;

    thead {
      display: none;
    }

    tbody {
      tr {
        overflow: hidden;
        position: relative;
        display: flex;
        width: 100%;
        border: 1px solid @c-grey-light;
        border-radius: 4px;
        height: 100px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        @media screen and (max-width: @bp-md-mx) {
          height: 150px;
        }
      }

      td {
        font-family: @primary-font;
        font-weight: 600;
        position: relative;
        height: 100%;
        border: none;
        display: flex;
        align-items: center;
        overflow: hidden;

        &:nth-child(1) {
          color: @c-primary;
          font-size: @font-24;
          flex: 0 0 90%;
          justify-content: flex-start;
          line-height: 1.1em;
          max-height: 55%;
          padding: 0 2.5rem;
          margin-top: auto;
          margin-bottom: auto;
          @media screen and (max-width: @bp-md-mx) {
            line-height: 1.3em;
          }
          > .list_title {
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
            @media screen and (max-width: @bp-md-mx) {
              -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3;
            }
          }
          @media screen and (max-width: @bp-md-mx) {
            font-size: @font-18;
          }
        }

        &:nth-child(2) {
          font-size: @font-18;
          justify-content: flex-end;
          flex: 0 0 10%;
          @media screen and (max-width: @bp-md-mx) {
            font-size: @font-14;
          }
          a {
            text-decoration: underline;
            color: #000;
            border-radius: 3px;
            margin: 0;
            flex: 0 0 100%;
            position: relative;
          }

          i.icon {
            position: relative;
            margin: 0;
            width: 100%;
            height: 100%;
            text-decoration: underline;
            &::before {
              padding: 7px;
              border-radius: 2px;
              cursor: pointer;
            }
          }
        }

        &[colspan="2"] {
          justify-content: flex-end;
          flex: 0 0 100%;
        }
      }
    }
  }
}

div[class$="SectionBody"] {
  margin: 0 auto;
  padding: 2.5rem 4rem 6rem 4rem;
  //max-width: 70rem;

  @media (min-width: @bp-xl-mx) {
    max-width: 70rem;
  }

  @media screen and (max-width: @bp-md-mx) {
    padding: 3rem 2rem 4rem 2rem;
  }

  .contentGrid {
    @media screen and (max-width: @bp-md-mx) {
      overflow-x: scroll;
      padding-bottom: 2rem;
    }
  }
}
