.elencoBandi__content {
  .elencoBandiSectionBody {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .ui.card {
      box-shadow: none;
      border: 1px solid @c-grey-light;
      color: @c-grey-dark;
      border-radius: 8px;
      margin: 0 0 2rem 0;
      width: calc(50% - 2rem);

      @media (max-width: @bp-lg-mx) {
        width: 100%;
        flex: 0 0 100%;
      }

      img.ui.image {
        position: absolute;
        width: 50px;
        height: 50px;
        margin-left: 1rem;
        top: 10px;
        right: 10px;
      }

      &:nth-child(2n-1) {
        margin-right: 4rem;

        @media (max-width: @bp-lg-mx) {
          margin-right: 0;
        }
      }

      &:hover {
        box-shadow: 6px 4px 24px rgba(131, 146, 161, 0.24);
        transform: scale(1.02, 1.02);
      }

      > .content {
        border-top: none;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        &:not(:first-child) {
          &::before {
            content: "";
            position: absolute;
            background: @c-grey-light;
            height: 1px;
            top: 0;
            left: 5%;
            width: 90%;
          }
        }
        .header {
          color: @c-primary;
          font: @secondary-font;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.32em;
          padding-right: 50px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 50px;
        }
        .sub.header {
          text-transform: none;
          font-family: @primary-font;
          font-size: 14px;
          line-height: 17px;
          font-weight: bold;
          color: @c-grey-dark;
          height: 20px;
        }
        .ui.sub.content {
          font-family: @primary-font;
          font-size: 16px;
          line-height: 1.4em;
          margin-top: 5px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .description {
          font-family: @primary-font;
          font-size: 14px;
          line-height: 1.4em;
          color: @c-grey-norm;
          margin-bottom: 2rem;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4; /* number of lines to show */
          line-clamp: 4;
          -webkit-box-orient: vertical;
          height: 80px;
        }
        .cta {
          position: absolute;
          bottom: 1.5rem;
          right: 1.5rem;
          > a {
            color: @c-secondary;
            font-family: @primary-font;
            font-weight: bold;
            text-decoration: underline;
          }
          > i.icon {
            color: @c-secondary;
          }
        }
      }
    }
  }
  .elencoBandiSectionBodyNoResults {
    display: flex;
    justify-content: center;
    font-family: @primary-font;
    font-size: 0.875rem;
    line-height: 1.5em;
    div {
      text-align: center;
      > img {
        margin: 3rem 0 1.5rem 0;
      }
    }
  }
  @media screen and (min-width: @bp-xl-mx) {
  }
}
