#app {
  .filterSidebar.filterSidebar--left {
    visibility: visible;
    display: flex;
    flex-direction: column;
    background: @c-background;
    width: @width-filterSidebar;
    padding: 2.5rem;
    .ui.grid {
      position: relative;
      .row {
        display: flex;
        flex-direction: column;
        position: relative;
        &.covered-amount {
          > .covered-amount-inputs {
            padding: 0.58035714em 0.875em;
            background: @c-white;
            border: 1px solid rgba(34, 36, 38, 0.15);
            width: 14em;
            border-radius: 0.25rem;
            .field {
              position: relative;
              margin-top: 2rem;
              label {
                color: @c-grey-mid;
                font-size: 1rem;
                line-height: 1.214em;
                position: absolute;
                z-index: 2;
                top: 0.7rem;
                left: 1rem;
                margin: 0 0 0 0;
                width: calc(100% - 1.3rem);
                pointer-events: none;
                transition: all 0.3s ease-out;
                padding-left: 0;
              }
              &:first-child {
                margin-bottom: 0.875em;
              }
              > .ui.input {
                display: block;
              }
              span {
                line-height: 1.2em;
              }
            }
            div.filled {
              .field {
                label {
                  top: -22px;
                  left: 0.5rem;
                  color: @c-primary;
                  font-size: @font-14;
                  line-height: 17px;
                }
              }
              span {
                line-height: 13px;
                border: 0;
                margin: 0;
              }
            }
          }
        }
      }
      .row.filter__buttons {
        padding: 1rem 0 1.5rem 0;
        display: flex;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: @height-topbar;
        background: @c-background;
        z-index: 3;
        border-bottom: 1px solid @c-grey-mid;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          -webkit-box-shadow: 0px 0px 1px .5px @c-grey-mid;
          -moz-box-shadow: 0px 0px 1px .5px @c-grey-mid;
          box-shadow: 0px 0px 1px .5px @c-grey-mid;
        }
        & + .row {
          padding-top: 2rem;
        }
        .column {
          padding: 0;
          display: inherit;
          align-items: inherit;
        }
      }
      label {
        color: @c-primary;
        font-family: @secondary-font;
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
      }
      .ui.input {
        width: 100%;

        input {
          font-family: @primary-font;

          &::placeholder {
            color: @c-grey-mid;
          }
        }
      }
      .row {
        &:first-child {
          label {
            font-size: @font-24;
            display: inline-block;
          }
        }
      }
      button.ui.button--underline {
        //margin-top: 1rem;
        margin-left: 1rem;
        > .button--text {
          &::after {
            left: 5px;
          }
        }
      }
    }

    @media screen and (max-width: @bp-md-mx) {
      min-width: auto;
    }
  }
}
