#app {
  .user-container {
    form#userProfileForm {
      .ui.checkbox.toggle {
        label {
          padding-left: 5rem;
          display: flex;
          align-items: center;
        }
        label:before {
          background: var(--c-success) !important;
        }
        &.inactive {
          label:before {
            background: var(--c-grey-light) !important;
          }
        }
        &.checked {
          label:before {
            background: var(--c-error) !important;
          }
        }
      }
      &.view {
        .ui.buttons.modify {
          button.ui.button.button--grid.active {
            pointer-events: none;
            opacity: 0.75;
          }
        }
      }
      .ui.buttons.modify {
        button.ui.button.button--grid {
          color: @c-grey-norm;
          border: 1px solid @c-grey-norm;
          opacity: 0.75;
          pointer-events: none;
          &.active {
            color: @c-secondary-dark;
            border: 1px solid @c-primary;
            pointer-events: all;
            opacity: 1;
            &.primary {
              color: @c-white;
              background: @c-primary;
            }
          }
        }
      }
      .ui.grid > .row {
        @media screen and (max-width: @bp-md-mx) {
          padding: 0;
        }

        h3.header {
          @media screen and (max-width: @bp-md-mx) {
            margin: 2rem 0 1rem;
          }
        }

        .field {
          @media screen and (max-width: @bp-md-mx) {
            padding-bottom: 1rem;
          }
        }

        .ui.buttons.modify {
          @media screen and (max-width: @bp-md-mx) {
            display: flex !important;
            flex-direction: column;
          }
        }

        > [class*="left aligned"] {
          display: flex;
          align-items: flex-end;
        }

        &:last-child {
          > div {
            @media screen and (max-width: @bp-md-mx) {
              text-align: left;
              margin-bottom: 1rem;
            }
          }
        }
      }

      .ui.grid.company-type {
        .ui.buttons {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 2rem;

          @media screen and (max-width: @bp-md-mx) {
            display: flex;
            flex-direction: column;
          }

          button {
            min-height: 9rem;
            padding: 1.125rem 2rem !important;
            background: transparent;
            border: 1px solid @c-grey-light;
            border-radius: 0.15rem;
            font-size: @font-18 !important;
            font-weight: 500;
            line-height: 1.2;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: @bp-md-mx) {
              margin-bottom: 1rem;
            }

            i.icon {
              margin: 0;
              font-size: @font-32;
            }

            &.primary {
              border: 2px solid @c-primary;
              color: #000;
              font-weight: 600;

              i.icon {
                color: @c-primary;
              }
            }
          }
        }
      }
    }
  }
  /* Change default semantic dropdown placeholder color */
  .ui.dropdown:not(.button) > .default.text,
  .ui.default.dropdown:not(.button) > .text {
    color: #989b9e;
  }
}
