div.homepage-container {
  div.ui.center.aligned.segment {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    .homeHeader {
      max-width: 50rem;
      margin: 0 auto;
      padding: 10vh 0;

      @media (max-width: @bp-md-mx) {
        padding: 3vh 0;
        margin: 0 1rem;
      }

      h1 {
        font-size: 2.5rem;
        margin-bottom: 0.75rem;
        color: @c-primary;

        @media (max-width: @bp-md-mx) {
          font-size: @font-28;
        }
      }

      p {
        margin: 0;
        font-family: @primary-font;
        font-size: @font-18;
        font-weight: 500;
      }
    }

    .homeTiles {
      max-width: 80rem;
      margin: 0 auto;
      margin-bottom: 4rem;
      padding: 0 2rem;
      display: flex;
      justify-content: center;

      @media (max-width: @bp-md-mx) {
        flex-wrap: wrap;
        margin: 0 1rem;
        padding: 0;
      }

      > a {
        color: @c-grey-dark;
        border: 1px solid @c-secondary;
        border-radius: 0.5rem;
        transition: transform .2s linear;
        flex: 0 0 20rem;

        @media (max-width: @bp-md-mx) {
          flex: 0 1 auto;
          margin-bottom: 2rem;
        }

        &:not(:last-child) {
          margin-right: 6rem;

          @media (max-width: @bp-md-mx) {
            margin-right: 0;
          }
        }

        .tile {
          margin: 0 !important;
          padding: 2.5rem;
          border-radius: 0.5rem;
          box-shadow: none;
          border: none;

          > i.icon {
            font-size: @font-28;
            color: @c-secondary;
            margin: 0;
          }

          .tileTitle {
            font-size: @font-24;
            margin: 1rem 0;
            color: @c-primary;
          }

          .tileDescription {
            font-family: @primary-font;
            font-size: @font-14;
            font-weight: 500;
            margin-bottom: 2rem;
          }
        }
        &:hover {
          transform: scale(1.05, 1.05);
          box-shadow: 6px 4px 24px rgba(@c-secondary-rgb, 0.24);
          transition: all .2s linear;
        }
      }
    }

    .homeMessage {
      font-size: @font-14;
      font-weight: 500;
      padding-top: 4rem;

      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}
