#app {
  .access {
    height: 100%;
    width: 100%;
    background-position: right center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: @bp-md-mx) {
      background-position: center -5%;
      background-size: contain;
    }

    .bg-image {
      position: absolute;
      height: 100%;
      right: 0;
      z-index: 0;
    }

    &__container {
      width: 35rem;
      padding: 2rem;
      background: @c-white;
      filter: drop-shadow(6px 4px 24px rgba(131, 146, 161, 0.24));
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: @bp-md-mx) {
        padding: 2rem 0 1.5rem;
        height: auto;
        width: 90%;
        margin: 3rem auto 0;
      }

      &__title {
        font-weight: 500;
        font-size: @font-24;
        margin: 2rem 0rem;
        color: @c-primary;

        @media (max-width: @bp-md-mx) {
          margin: 2rem 0 1rem;
        }
      }
    }

    h1.header[class*="__title"] {
      font-weight: 500;

      span {
        font-weight: 700;
      }
    }

    .login__form {
      padding-top: 2rem;

      @media screen and (max-width: @bp-md-mx) {
        margin: 0 1.25rem;
        padding-top: 0;
      }

      input {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid @c-grey-light;
        padding: .5rem 0 .75rem;
        font-family: @primary-font;

        &:focus {
          border-color: @c-secondary;
        }
      }

      .user--blocked {
        color: @c-error;
        font-weight: 700;
      }

      .login__password-row {
        padding: .5rem 0 2rem;

        @media screen and (max-width: @bp-md-mx) {
          padding: .5rem 0;
        }

        .login__password-recovery {
          margin-left: auto;
          text-align: right;

          @media screen and (max-width: @bp-md-mx) {
            //width: 100% !important;
            margin-bottom: 1rem;
          }

          .user--blocked {
            pointer-events: none;
            cursor: default;
          }

          a {
            font-family: @primary-font;
            font-size: @font-14;
            color: @c-secondary;
          }
        }
      }
    }
  }
}