#app {
  .ui.sidebar.sidebar--left {
    visibility: visible;
    display: flex;
    flex-direction: column;
    background: @c-background;
    min-width: 5rem;
    padding-top: calc(@height-topbar + 1rem);

    @media screen and (max-width: @bp-xmd-mx) {
      min-width: auto;
    }

    .sidebar__item {
      text-align: center;
      padding: 0.75rem 1.5rem;

      @media screen and (max-width: @bp-xmd-mx) {
        padding: 0.75rem 1rem;
      }

      .icon,
      .ui.image {
        color: @c-secondary;
        margin: 0 auto;
      }

      &:hover {
        .icon,
        .ui.image {
          color: @c-primary;
        }
      }

      &.active {
        background: @c-secondary;
        .icon,
        .ui.image {
          color: @c-background;
        }
      }
    }
  }
}
