:root.assilea {
  --secondary-font: "pt-sans", sans-serif;
  --primary-font: "Arial", "Lucida Grande", sans-serif;
  --standard-font: "Arial", "Lucida Grande", sans-serif;
  --gradient-deg: 90deg;
  --c-primary: #2ba1df;
  --c-primary-rgb: 43, 161, 223;
  --c-primary-light: #aad9f2;
  --c-secondary: #3a3a3a;
  --c-secondary-rgb: 58, 58, 58;
  --c-secondary-dark: #303030;
  --c-secondary-light: #7f7f7f;
  --c-background: #f4f4f4;
  --c-footer-background: #2ba1df;
  --c-footer: #fff;
  --c-footer-border: #2ba1df;
  --c-grey-light: #dae0e7;
  --c-grey-button: #ebebeb;
  --c-grey-background: #e5e5e5;
  --c-grey-mid: #9c9c9c;
  --c-grey-norm: #757575;
  --c-grey-dark: #1d1d1d;
  --c-black-light: #262626;
  --c-white: #ffffff;
}
