div.footer {
  position: relative;
  border-top: 1px solid @c-footer-border;
  bottom: 0;
  width: 100%;
  background: @c-footer-background;
  font-size: 1rem;
  font-weight: 400;
  font-family: @primary-font;
  line-height: 24px;
  color: @c-footer;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem 4rem;
  height: 146px;

  @media screen and (max-width: 835px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1375px) {
    height: auto;
  }

  @media (max-width: @bp-md-mx) {
    padding: 2rem;
  }

  > div {
    width: 50%;
    @media screen and (max-width: 1375px) {
      &:first-child {
        width: 30%;
      }
    }
    @media screen and (max-width: 1375px) {
      &:last-child {
        width: 70%;
      }
    }
    @media screen and (max-width: 1041px) {
      &:first-child {
        width: 50%;
      }
      &:last-child {
        width: 50%;
      }
    }
    @media screen and (max-width: 960px) {
      &:first-child {
        width: 40%;
      }
      &:last-child {
        width: 60%;
      }
    }
    @media screen and (max-width: 835px) {
      &:first-child {
        width: 100%;
        margin-bottom: 30px;
      }
      &:last-child {
        width: 100%;
      }
    }
  }

  div.footer-policies {
    font-weight: 600;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1216px) {
      flex-direction: column;
    }
    > a {
      color: @c-footer;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex: 0 0 fit-content;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 100%;
        border-bottom: 1px solid @c-white;
        left: 0;
        bottom: 0;
        background: transparent;
        transition: width 0.3s linear;
      }
      &:hover::after {
      //&.active::after {
        width: 100%;
        transition: width 0.3s linear;
      }
      > div {
        padding: 0;
        margin: 0;
      }
      &:not(:first-child) {
        margin-left: 20px;
      }
      @media screen and (max-width: 1375px) {
        &:nth-child(2n-1) {
          margin-left: 20px;
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 10px;
        }
        &:first-child,
        &:nth-child(3) {
          margin-left: 0px;
        }
      }
      @media screen and (max-width: 1216px) {
        &:nth-child(3) {
          margin-bottom: 10px;
        }
        &:not(:first-child) {
          margin-left: 0px;
        }
        width: fit-content;
      }
    }
  }

  > .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      justify-content: flex-end;
      .powered-by {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        > img {
          display: flex;
          object-fit: contain;
          margin-left: 10px;
          flex: 0 0 auto;
          width: auto;
          height: 35px;
        }
        @media screen and (max-width: 1041px) {
          margin-bottom: 15px;
        }
        @media screen and (max-width: 600px) {
          flex-direction: column;
          margin-bottom: 20px;
          align-items: flex-start;
          > img {
            margin-bottom: 15px;
            margin-left: 0;
            height: 35px;
            flex: 0 0 35px;
            &:first-child {
              margin-top: 10px;
            }
          }
        }
      }
      > .icons {
        position: relative;
        display: flex;
        margin-left: 20px;
        margin-bottom: 10px;
        height: 100%;
        align-items: center;
        > a {
          color: @c-footer;
          i.icon {
            font-size: 1.5rem;
          }
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
        @media screen and (max-width: 835px) {
          margin-left: 0;
        }
      }
      @media screen and (max-width: 1041px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
      @media screen and (max-width: 835px) {
        align-items: flex-start;
      }
    }
    .copyrights {
      @media screen and (max-width: 1041px) {
        text-align: right;
      }
      @media screen and (max-width: 835px) {
        text-align: left;
      }
    }
    @media screen and (max-width: 835px) {
      align-items: flex-start;
    }
  }
}
