#app {
	.customer-container {
		// .customerHeader {
		// 	margin: 0;
		// 	padding: 2rem;

		// 	@media screen and (max-width: @bp-md-mx) {
		// 		padding: 1rem;
		// 	}

		// 	.ui.grid {
		// 		margin: 0;
		// 		align-items: center;

		// 		.column {
		// 			padding: 0;

		// 			@media screen and (max-width: @bp-md-mx) {
		// 				width: 100% !important;
		// 				padding-bottom: 1rem;
		// 			}
		// 		}
		// 	}

		// 	.padded.grid {
		// 		.row {
		// 			padding: 0;
		// 			align-items: center;

		// 			.field {
		// 				margin: 0 1.5rem 0 0;
		// 				display: flex;
		// 			}
		// 		}

		// 		.customer__buttons {
		// 			display: flex;

		// 			>.button--primary {
		// 				margin-right: 1.5rem;
		// 			}
		// 		}

		// 		.new_customer__buttons {
		// 			display: flex;
		// 			margin-left: 0;

		// 			>.button--primary {
		// 				margin-right: 1.5rem;
		// 			}
		// 		}
		// 	}
		// }

		.customerSectionBody {
			margin: 0 auto;
			padding: 2.5rem 6rem 4rem;

			@media (max-width: @bp-2xl-mx) {
				max-width: 70rem;
			}

			@media screen and (max-width: @bp-md-mx) {
				padding: 2.5rem 1rem 4rem;
			}

			.contentGrid {
				div {
					table {
						tbody {
							tr {
								background: @c-background;
								border: 5px solid @c-grey-light;
							}
						}
					}
				}
				@media screen and (max-width: @bp-md-mx) {
					overflow-x: scroll;
					padding-bottom: 2rem;
				}
			}
		}

		.customerSectionActions {
			.position--left {
				float: left;
			}

			.position--right {
				float: right;
			}

			padding-top: 1.5rem;
			height: auto;
		}
	}
}