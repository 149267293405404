.dashboard-container {
  .dashboard__content {
    .dashboardHeader {
      padding: 2rem;
      display: flex;
      > .ui.grid {
        margin: 0;
        width: 100%;
        .column {
          padding: 0;
          display: flex;
          align-items: center;
          i {
            color: #8098b2;
            margin-right: 1rem;
          }
          h1 {
            font-weight: 500;
            margin-top: 0;
            line-height: 1em;
          }
          &:first-child {
            width: 35vw;
          }
        }
      }
    }
    .dashboardBody {
      padding: 2em 4em;
      .row {
        display: flex;
        height: 100%;
        height: 500px;
      }
      .statsGrid {
        display: flex;
        flex-flow: row wrap;
        width: 30%;
        justify-content: space-between;
        height: 100%;
        > .stat {
          width: 45%;
          background: white;
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 4px;
          transition: transform 0.3s linear;
          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 2rem;
          }
          > div {
            margin: 0;
            > .statName {
              color: #8098b2;
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
            }
            > i {
              width: 56px;
              height: 56px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f9f9f9;
              border-radius: 50%;
              color: #8098b2;
              margin: 0 0 15px 0;
              padding: 0;
              &::before {
                font-size: 1.5rem;
              }
            }
            h1 {
              font-size: 48px;
              line-height: 66px;
              margin: 5px 0;
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            background: #f9f9f9;
            i {
              background: white;
            }
          }
          > .untilToday {
            color: #9b9b9b;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
          }
          &:hover {
            box-shadow: 4px 4px 8px rgba(7, 7, 7, 0.22);
            transform: scale(105%);
            transition: transform 0.5s linear;
          }
        }
      }
      .chart {
        width: 70%;
        padding: 0 2em;
      }
      #charts {
        height: 100%;
        background: white;
        border-radius: 4px;
        padding: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        > * {
          canvas {
            font-family: inherit;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .dashboard__content {
      .dashboardHeader {
        padding: 1rem;
      }
    }
  }
}
