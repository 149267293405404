:root.mediobanca {
  --secondary-font: "Noto Serif", "Times New Roman", serif;
  --primary-font: "Inter", "Helvetica", sans-serif;
  --standard-font: "Arial", "Montserrat", "Helvetica", sans-serif;
  --gradient-deg: 90deg;
  --c-primary: #023367;
  --c-primary-rgb: 2, 51, 103;
  --c-primary-light: #2b6499;
  --c-secondary: #c3a157;
  --c-secondary-rgb: 195, 161, 87;
  --c-secondary-dark: #625129;
  --c-secondary-light: #e8c780;
  --c-background: #f8f4eb;
  --c-footer-background: #023367;
  --c-footer: #fff;
  --c-footer-border: #023367;
  --c-grey-light: #dae0e7;
  --c-grey-button: #ebebeb;
  --c-grey-background: #e5e5e5;
  --c-grey-mid: #9b9b9b;
  --c-grey-norm: #72717a;
  --c-grey-dark: #272b30;
  --c-black-light: #222b3d;
  --c-white: #ffffff;
}
