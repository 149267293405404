#app {
  .filterEdit__content {
    position: relative;
  }
  .filterEditSectionBody {
    position: relative;
    padding: 2.5rem 4rem 10rem 4rem;
    .reloader {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      transform: translateY(-50%);
    }
  }
  .rst__tree {
    position: relative;
    width: 100%;
    .rst__node {
      &:first-child {
        .rst__nodeContent {
          width: 80%;
          max-width: 700px;
          .rst__rowWrapper {
            .rst__row {
              border: none;
              .rst__rowContents {
                background-color: @c-primary;
                .rst__rowLabel {
                  .rst__rowTitle {
                    color: @c-white;
                    .field .ui.input {
                      input {
                        background: transparent;
                        color: @c-white;
                        font-weight: 600;
                      }
                    }
                  }
                }
                .rst__rowToolbar {
                  .rst__toolbarButton {
                    button.ui.button {
                      color: @c-primary;
                      background: @c-primary-light;
                    }
                    .ui.button > .icon:not(.button) {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .rst__nodeContent {
        width: 80%;
        max-width: 1000px;
        min-width: 500px;
        .rst__rowWrapper {
          .rst__row {
            position: relative;
            border: 1px solid @c-grey-mid;
            position: relative;
            border-radius: 2px;
            .rst__moveHandle {
              position: relative;
              background: transparent;
              box-shadow: none;
              border: none;
              flex: 0 0 40px;
              width: 40px;
              &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 10px;
                background: url('data:image/svg+xml,%3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 400"%3E%3Cpath fill="%23272B30" d=" M16 316H432C440.837 316 448 323.163 448 332V372C448 380.837 440.837 388 432 388H16C7.163 388 0 380.837 0 372V332C0 323.163 7.163 316 16 316zM16 156H432C440.837 156 448 163.163 448 172V212C448 220.837 440.837 228 432 228H16C7.163 228 0 220.837 0 212V172C0 163.163 7.163 156 16 156zM16 -4H432C440.837 -4 448 3.163 448 12V52C448 60.837 440.837 68 432 68H16C7.163 68 0 60.837 0 52V12C0 3.163 7.163 -4 16 -4z" /%3E%3C/svg%3E');
                background-repeat: no-repeat;
                background-position: center;
                padding: 2px;
                display: flex;
              }
            }
            .rst__rowContents {
              position: relative;
              box-shadow: none;
              flex: 1;
              border: none;
              display: flex;
              .rst__rowLabel {
                position: relative;
                flex: 1;
                .rst__rowTitle {
                  padding: 3px 0;
                  height: 100%;
                  flex: 0 0 100%;
                  .field {
                    position: relative;
                    height: 100%;
                    .ui.input {
                      input {
                        position: relative;
                        border: none;
                        pointer-events: none;
                        flex: 1;
                        padding: 0.5rem 0.6rem;
                        &.isActive {
                          pointer-events: all;
                          border: 1px solid @c-grey-light;
                        }
                      }
                    }
                    &.error {
                      margin: 0;
                      .ui.input {
                        input {
                          &.isActive {
                            border-color: @c-error;
                          }
                        }
                      }
                    }
                    &.error ~ span {
                      font-family: @primary-font;
                      font-weight: normal;
                      position: absolute;
                      bottom: 50%;
                      left: 0.6rem;
                      margin-top: 0;
                      line-height: 1.5em;
                      transform: translateY(50%);
                    }
                  }
                }
              }
              .rst__rowToolbar {
                height: 100%;
                display: flex;
                justify-content: flex-end;
                flex: 0 0 auto;
                .rst__toolbarButton {
                  height: 100%;
                  padding: 2px 0;
                  button.ui.button {
                    height: 100%;
                    width: 2.5rem;
                    color: @c-secondary;
                    background: @c-background;
                    &:not(:first-child) {
                      margin-left: 3px;
                    }
                    &.isDisabled {
                      background: #e0b4b4;
                      color: @c-error;
                      opacity: 0.6;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .rst__rowLandingPad,
  .rst__rowCancelPad {
    border: none !important;
    box-shadow: none !important;
    outline: 1px black solid;
  }
  .rst__rowLandingPad > *,
  .rst__rowCancelPad > * {
    opacity: 0 !important;
  }
  .rst__rowLandingPad::before,
  .rst__rowCancelPad::before {
    background-color: transparent;
    border: 1px dashed @c-grey-mid;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .rst__collapseButton,
  .rst__expandButton {
    appearance: none;
    border: none;
    position: absolute;
    border-radius: 3px;
    background: @c-primary;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      width: 12px;
      height: 1px;
      background: @c-white;
      top: 50%;
      left: 50%;
      position: absolute;
      transform-origin: center;
    }
  }
  .rst__collapseButton {
    &::after,
    &::before {
      transform: translate(-50%, -1px);
    }
  }
  .rst__expandButton {
    &::before {
      transform: translate(-50%, -1px);
    }
    &::after {
      right: 0;
      left: auto;
      transform: rotate(90deg) translate(-0.5px, 2px);
    }
  }
  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    outline: none;
    box-shadow: 1px 1px 1px 1px rgba(131, 146, 161, 0.24);
  }
  .rst__collapseButton:hover:not(:active),
  .rst__expandButton:hover:not(:active) {
    height: 19px;
    width: 19px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 1px rgba(131, 146, 161, 0.24);
  }
  .rst__expandButton:hover:not(:active) {
    &::after {
      transform: rotate(90deg) translate(-0.5px, 3.5px);
    }
  }

  .rst__lineBlock::after,
  .rst__lineChildren::after,
  .rst__lineHalfHorizontalRight::before::before {
    background-color: @c-grey-dark;
  }
}

.ui.modal.active.tooltip__edit {
  div.header {
    color: @c-primary;
  }
  .content {
    display: flex;
    flex-direction: column;
    label {
      font-size: @font-18;
      font-weight: 600;
      font-family: @secondary-font;
    }
    div#editor {
      margin-top: 1rem;
      .ck.ck-editor {
        .ck.ck-editor__main {
          .ck-editor__editable {
            height: 12rem;
          }
        }
      }
    }
    button.ui.button {
      margin-top: 1rem;
      align-self: flex-end;
      margin-right: 10px;
      float: right;
      i.icon {
        margin-right: 6px;
      }
    }
  }
}
