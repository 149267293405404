@import "/src/assets/less/utils/variables_cliccabandi.css";
@import "/src/assets/less/utils/variables_assilea.css";
@import "/src/assets/less/utils/variables_mediobanca.css";
@import "/src/assets/less/utils/variables_valsabbina.css";
/* FONT
  ----------------------------- */
/* SIDEBAR SIZE
    ----------------------------- */
/* FILTER SIDEBAR SIZE
    ----------------------------- */
/* TOPBAR SIZE
    ----------------------------- */
/* FOOTER SIZE
    ----------------------------- */
/* CHECKBOX SIZE
    ----------------------------- */
/* FONT SIZE
    ----------------------------- */
/* GRADIENT DEGREE
  ----------------------------- */
/* COLORS
  ----------------------------- */
/* BREAKPOINTS
    ----------------------------- */
@font-face {
  font-family: 'Noto Serif';
  src: url('/src/assets/fonts/NotoSerif-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Serif';
  src: url('/src/assets/fonts/NotoSerif-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Serif';
  src: url('/src/assets/fonts/NotoSerif-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Serif';
  src: url('/src/assets/fonts/NotoSerif-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/src/assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/src/assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/src/assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'pt-sans';
  src: url('/src/assets/fonts/PtSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'pt-sans';
  src: url('/src/assets/fonts/PtSans-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'pt-sans';
  src: url('/src/assets/fonts/PtSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'pt-sans';
  src: url('/src/assets/fonts/PtSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
div#app {
  height: 100vh;
  width: 100vw;
  font-family: var(--secondary-font) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  position: relative;
}
div#app h1,
div#app h2,
div#app h3,
div#app h4,
div#app h5 {
  font-family: var(--secondary-font);
}
* {
  box-sizing: border-box;
}
.fluid {
  width: 100%;
  height: auto;
}
.fluid-y {
  height: 100%;
}
.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.pointer {
  cursor: pointer;
}
h2 {
  font-size: 1.5rem;
  line-height: 1.1em;
  font-weight: bold;
}
/* ------ HEADER ------ */
#app .sidebar.sidebar--left + .topbar {
  padding-left: 7rem;
}
@media (max-width: 48rem) {
  #app .sidebar.sidebar--left + .topbar {
    padding: 1rem;
  }
}
#app .topbar {
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1.75rem 3.5rem;
  border-bottom: 1px solid var(--c-grey-light);
  border-radius: 0;
  background: var(--c-white);
  z-index: 999;
  height: 6.25rem;
}
@media screen and (max-width: 48rem) {
  #app .topbar {
    padding: 1rem 2rem;
  }
}
#app .topbar .item {
  padding: 0;
}
#app .topbar .item button.ui.button {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}
@media screen and (max-width: 48rem) {
  #app .topbar .item button.ui.button {
    height: 2.75rem;
    width: 2.75rem;
    font-size: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .left.menu > a.item {
    margin-left: 0;
  }
}
#app .topbar .left.menu > a.item:not(:first-child) {
  margin-left: 2rem;
}
#app .topbar .left.menu > a.item:not(:first-child) .item {
  color: var(--c-primary);
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1rem;
  padding: 5px 0;
}
#app .topbar .left.menu > a.item:not(:first-child) .item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  border-bottom: 1px solid var(--c-primary);
  left: 0;
  bottom: 0;
  background: transparent;
  transition: width 0.3s linear;
}
#app .topbar .left.menu > a.item:not(:first-child).active .item,
#app .topbar .left.menu > a.item:not(:first-child):hover .item {
  color: var(--c-secondary);
  transition: width 0.3s linear;
}
#app .topbar .left.menu > a.item:not(:first-child).active .item::after,
#app .topbar .left.menu > a.item:not(:first-child):hover .item::after {
  width: 100%;
  border-color: var(--c-secondary);
}
#app .topbar .left.menu > a.item:first-child {
  margin-left: 1rem;
}
@media screen and (max-width: 48rem) {
  #app .topbar .left.menu > a.item:first-child {
    margin-left: 0;
  }
}
#app .topbar .left.menu > a.item .ui.image {
  width: 14rem;
  height: 3.6rem;
}
#app .topbar .right.menu {
  color: var(--c-primary);
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 600;
}
#app .topbar .right.menu > .sign__out {
  display: flex;
  align-items: center;
  cursor: default;
  text-align: right;
}
@media screen and (max-width: 48rem) {
  #app .topbar .right.menu > .sign__out {
    font-size: 0;
  }
}
#app .topbar .right.menu > .sign__out > a {
  margin-left: 10px;
}
@media screen and (max-width: 48rem) {
  #app .topbar .right.menu > .sign__out > a {
    margin-left: 0;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .right.menu > .sign__out > a i {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .right.menu {
    margin-left: 0.75rem;
  }
}
/* SINGLE-PAGE HEADER STYLE */
#app .ui.header {
  color: var(--c-primary);
  font-family: inherit;
}
#app .ui.dividing.header {
  color: var(--c-primary);
  font-size: 2rem;
  font-weight: 600;
  border-bottom: none;
  margin: 0;
  padding: 2rem 4rem;
}
@media screen and (max-width: 48rem) {
  #app .ui.dividing.header {
    padding: 2rem 2rem;
  }
}
#app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .column {
  margin-bottom: 1rem;
}
@media screen and (max-width: 1100px) {
  #app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .column {
    flex: 0 0 calc(50% - 0.5rem);
  }
  #app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .column:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 800px) {
  #app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .column {
    flex: 0 0 100%;
  }
  #app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .column:not(:last-child) {
    padding-bottom: 1rem;
  }
}
#app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .button-column {
  flex: 0 0 fit-content;
}
#app .ui.dividing.header.userHeader .ui.grid > .row:not(.first-row) .button-column > .field {
  margin-left: 0;
}
#app .ui.dividing.header .ui.grid {
  margin: 0;
  align-items: center;
}
#app .ui.dividing.header .ui.grid .column {
  padding: 0;
}
#app .ui.dividing.header .ui.grid > .row {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  position: relative;
  width: 100%;
  padding: 2rem 0;
}
#app .ui.dividing.header .ui.grid > .row.first-row {
  border-bottom: 1px solid var(--c-grey-light);
  justify-content: space-between;
}
#app .ui.dividing.header .ui.grid > .row.first-row > .column {
  flex: 0 0 fit-content;
}
@media screen and (max-width: 48rem) {
  #app .ui.dividing.header .ui.grid > .row.first-row > .column {
    flex: 0 0 100%;
  }
  #app .ui.dividing.header .ui.grid > .row.first-row > .column:not(:last-child) {
    padding-bottom: 1rem;
  }
}
#app .ui.dividing.header .ui.grid > .row:not(.first-row) > .column {
  margin-bottom: 1rem;
}
#app .ui.dividing.header .ui.grid > .row:not(.first-row) > .column:not(.button-column) {
  margin-right: 1rem;
}
@media screen and (max-width: 920px) {
  #app .ui.dividing.header .ui.grid > .row:not(.first-row) > .column:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 52rem) {
  #app .ui.dividing.header .ui.grid > .row:not(.first-row) > .column {
    flex: 0 0 100%;
    padding-bottom: 1rem;
  }
}
#app .ui.dividing.header .ui.grid > .row > .column {
  flex: 0 0 25%;
}
@media screen and (max-width: 920px) {
  #app .ui.dividing.header .ui.grid > .row > .column {
    flex: 0 0 calc(50% - 0.5rem);
  }
  #app .ui.dividing.header .ui.grid > .row > .column:nth-child(2n) {
    margin-right: 0;
  }
}
#app .ui.dividing.header .ui.grid > .row > .column[class$="buttons"] {
  height: 50px;
}
@media screen and (max-width: 48rem) {
  #app .ui.dividing.header .ui.grid > .row > .column[class$="buttons"] {
    height: fit-content;
  }
}
#app .ui.dividing.header .ui.grid > .row .button-column {
  display: flex;
  align-items: center;
  flex: 0 0 fit-content;
  margin-right: 0;
  margin-bottom: 0;
  min-height: 42px;
}
@media screen and (max-width: 52rem) {
  #app .ui.dividing.header .ui.grid > .row .button-column {
    margin-top: 1rem;
  }
}
#app .ui.dividing.header .ui.grid > .row .button-column .button--reset {
  margin-left: 1rem;
}
@media screen and (max-width: 52rem) {
  #app .ui.dividing.header .ui.grid > .row .button-column .field {
    margin-left: 0;
  }
}
#app .ui.dividing.header .ui.grid > .row .field {
  margin: 0;
  display: flex;
}
div.footer {
  position: relative;
  border-top: 1px solid var(--c-footer-border);
  bottom: 0;
  width: 100%;
  background: var(--c-footer-background);
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--primary-font);
  line-height: 24px;
  color: var(--c-footer);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem 4rem;
  height: 146px;
}
@media screen and (max-width: 835px) {
  div.footer {
    flex-direction: column;
  }
}
@media screen and (max-width: 1375px) {
  div.footer {
    height: auto;
  }
}
@media (max-width: 48rem) {
  div.footer {
    padding: 2rem;
  }
}
div.footer > div {
  width: 50%;
}
@media screen and (max-width: 1375px) {
  div.footer > div:first-child {
    width: 30%;
  }
}
@media screen and (max-width: 1375px) {
  div.footer > div:last-child {
    width: 70%;
  }
}
@media screen and (max-width: 1041px) {
  div.footer > div:first-child {
    width: 50%;
  }
  div.footer > div:last-child {
    width: 50%;
  }
}
@media screen and (max-width: 960px) {
  div.footer > div:first-child {
    width: 40%;
  }
  div.footer > div:last-child {
    width: 60%;
  }
}
@media screen and (max-width: 835px) {
  div.footer > div:first-child {
    width: 100%;
    margin-bottom: 30px;
  }
  div.footer > div:last-child {
    width: 100%;
  }
}
div.footer div.footer-policies {
  font-weight: 600;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1216px) {
  div.footer div.footer-policies {
    flex-direction: column;
  }
}
div.footer div.footer-policies > a {
  color: var(--c-footer);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 0 0 fit-content;
  position: relative;
}
div.footer div.footer-policies > a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  border-bottom: 1px solid var(--c-white);
  left: 0;
  bottom: 0;
  background: transparent;
  transition: width 0.3s linear;
}
div.footer div.footer-policies > a:hover::after {
  width: 100%;
  transition: width 0.3s linear;
}
div.footer div.footer-policies > a > div {
  padding: 0;
  margin: 0;
}
div.footer div.footer-policies > a:not(:first-child) {
  margin-left: 20px;
}
@media screen and (max-width: 1375px) {
  div.footer div.footer-policies > a:nth-child(2n-1) {
    margin-left: 20px;
  }
  div.footer div.footer-policies > a:nth-child(1),
  div.footer div.footer-policies > a:nth-child(2) {
    margin-bottom: 10px;
  }
  div.footer div.footer-policies > a:first-child,
  div.footer div.footer-policies > a:nth-child(3) {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1216px) {
  div.footer div.footer-policies > a {
    width: fit-content;
  }
  div.footer div.footer-policies > a:nth-child(3) {
    margin-bottom: 10px;
  }
  div.footer div.footer-policies > a:not(:first-child) {
    margin-left: 0px;
  }
}
div.footer > .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
div.footer > .right .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-end;
}
div.footer > .right .top .powered-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
div.footer > .right .top .powered-by > img {
  display: flex;
  object-fit: contain;
  margin-left: 10px;
  flex: 0 0 auto;
  width: auto;
  height: 35px;
}
@media screen and (max-width: 1041px) {
  div.footer > .right .top .powered-by {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  div.footer > .right .top .powered-by {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  div.footer > .right .top .powered-by > img {
    margin-bottom: 15px;
    margin-left: 0;
    height: 35px;
    flex: 0 0 35px;
  }
  div.footer > .right .top .powered-by > img:first-child {
    margin-top: 10px;
  }
}
div.footer > .right .top > .icons {
  position: relative;
  display: flex;
  margin-left: 20px;
  margin-bottom: 10px;
  height: 100%;
  align-items: center;
}
div.footer > .right .top > .icons > a {
  color: var(--c-footer);
}
div.footer > .right .top > .icons > a i.icon {
  font-size: 1.5rem;
}
div.footer > .right .top > .icons > a:not(:first-child) {
  margin-left: 8px;
}
@media screen and (max-width: 835px) {
  div.footer > .right .top > .icons {
    margin-left: 0;
  }
}
@media screen and (max-width: 1041px) {
  div.footer > .right .top {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}
@media screen and (max-width: 835px) {
  div.footer > .right .top {
    align-items: flex-start;
  }
}
@media screen and (max-width: 1041px) {
  div.footer > .right .copyrights {
    text-align: right;
  }
}
@media screen and (max-width: 835px) {
  div.footer > .right .copyrights {
    text-align: left;
  }
}
@media screen and (max-width: 835px) {
  div.footer > .right {
    align-items: flex-start;
  }
}
@keyframes on-hover-button {
  0% {
    background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary-dark) 100%);
    background-size: 200% 200%;
  }
  100% {
    background-position: 100% 100%;
  }
}
button.ui.button {
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary-dark) 100%);
  border-radius: 4px;
  color: var(--c-white);
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1rem;
  padding: 0 0;
  margin-right: 0;
}
button.ui.button > div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 2rem;
  background: transparent;
  border-radius: inherit;
  border: none;
}
button.ui.button > div i.icon:only-child {
  margin: 0 0.5rem 0 0;
}
button.ui.button:hover,
button.ui.button:focus,
button.ui.button:active {
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary-dark) 100%);
  color: var(--c-white);
}
button.ui.button:hover > div,
button.ui.button:focus > div,
button.ui.button:active > div {
  animation: on-hover-button 0.2s linear;
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary-dark) 100%);
  pointer-events: none;
}
button.ui.button.disabled {
  color: var(--c-white);
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary) 100%) !important;
  cursor: not-allowed;
  opacity: 0.75 !important;
}
.ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin: 0 0 0 0;
}
button.ui.button.primary,
button.ui.button--primary,
.button--primary button.ui.button {
  border-radius: 4px;
  padding: 15px 2rem;
  margin: 0;
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary) 100%);
  color: var(--c-white);
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2 linear;
}
button.ui.button.primary:hover,
button.ui.button--primary:hover,
.button--primary button.ui.button:hover,
button.ui.button.primary:focus,
button.ui.button--primary:focus,
.button--primary button.ui.button:focus,
button.ui.button.primary:active,
button.ui.button--primary:active,
.button--primary button.ui.button:active {
  color: var(--c-white);
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary) 100%);
}
button.ui.button--primary--negative,
.button--primary--negative button.ui.button {
  border-radius: 4px;
  background: transparent;
  color: var(--c-primary);
  border-color: var(--c-primary);
  border: 2px solid var(--c-primary);
  padding: 15px 2rem !important;
}
button.ui.button--primary--negative:hover,
.button--primary--negative button.ui.button:hover,
button.ui.button--primary--negative:focus,
.button--primary--negative button.ui.button:focus,
button.ui.button--primary--negative:active,
.button--primary--negative button.ui.button:active {
  background: var(--c-primary);
  color: var(--c-white);
}
button.ui.button--underline,
a.ui.button--underline {
  position: relative;
  background: transparent;
  padding: 0 !important;
  margin: 0;
  display: flex;
  align-items: center;
  height: fit-content;
}
button.ui.button--underline > div,
a.ui.button--underline > div,
button.ui.button--underline i.icon,
a.ui.button--underline i.icon {
  background: transparent !important;
  width: fit-content;
}
button.ui.button--underline > .button--text,
a.ui.button--underline > .button--text,
button.ui.button--underline i.icon,
a.ui.button--underline i.icon {
  color: var(--c-secondary);
  transition: all 0.3s ease-out;
}
button.ui.button--underline > .button--text,
a.ui.button--underline > .button--text {
  position: relative;
  padding: 3px 5px;
  line-height: 1.2em;
}
button.ui.button--underline > .button--text::after,
a.ui.button--underline > .button--text::after {
  content: "";
  background: transparent;
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  right: 5px;
  border-bottom: 1px solid var(--c-secondary);
  transition: all 0.3s ease-out;
  transform-origin: bottom right;
}
button.ui.button--underline:hover,
a.ui.button--underline:hover,
button.ui.button--underline:focus,
a.ui.button--underline:focus,
button.ui.button--underline:active,
a.ui.button--underline:active {
  background: transparent;
}
button.ui.button--underline:hover > div,
a.ui.button--underline:hover > div,
button.ui.button--underline:focus > div,
a.ui.button--underline:focus > div,
button.ui.button--underline:active > div,
a.ui.button--underline:active > div {
  background: transparent;
}
button.ui.button--underline:hover > .button--text,
a.ui.button--underline:hover > .button--text,
button.ui.button--underline:focus > .button--text,
a.ui.button--underline:focus > .button--text,
button.ui.button--underline:active > .button--text,
a.ui.button--underline:active > .button--text,
button.ui.button--underline:hover i.icon,
a.ui.button--underline:hover i.icon,
button.ui.button--underline:focus i.icon,
a.ui.button--underline:focus i.icon,
button.ui.button--underline:active i.icon,
a.ui.button--underline:active i.icon {
  color: var(--c-secondary);
  transition: all 0.3s ease-out;
}
button.ui.button--underline:hover > .button--text::after,
a.ui.button--underline:hover > .button--text::after,
button.ui.button--underline:focus > .button--text::after,
a.ui.button--underline:focus > .button--text::after,
button.ui.button--underline:active > .button--text::after,
a.ui.button--underline:active > .button--text::after,
button.ui.button--underline:hover i.icon::after,
a.ui.button--underline:hover i.icon::after,
button.ui.button--underline:focus i.icon::after,
a.ui.button--underline:focus i.icon::after,
button.ui.button--underline:active i.icon::after,
a.ui.button--underline:active i.icon::after {
  border-color: var(--c-secondary);
  transition: all 0.3s ease-out;
}
button.ui.button--underline:hover > .button--text::after,
a.ui.button--underline:hover > .button--text::after,
button.ui.button--underline:active > .button--text::after,
a.ui.button--underline:active > .button--text::after,
button.ui.button--underline:hover i.icon::after,
a.ui.button--underline:hover i.icon::after,
button.ui.button--underline:active i.icon::after,
a.ui.button--underline:active i.icon::after {
  width: calc(100% - 10px);
}
button.ui.button--underline:disabled,
a.ui.button--underline:disabled {
  color: var(--c-white);
  background-color: var(--c-secondary-dark);
  cursor: not-allowed;
}
button.ui.button--underline--negative,
a.ui.button--underline--negative {
  text-decoration: underline;
  background: transparent;
  padding: 0.9rem 0.5rem !important;
  color: var(--c-primary);
  font-weight: 500;
}
button.ui.button--underline--negative:hover,
a.ui.button--underline--negative:hover,
button.ui.button--underline--negative:focus,
a.ui.button--underline--negative:focus,
button.ui.button--underline--negative:active,
a.ui.button--underline--negative:active {
  background: transparent;
}
button.ui.button--reset:hover > .button--text,
button.ui.button--reset:hover i.icon {
  color: var(--c-primary);
}
button.ui.button--reset:hover > .button--text::after,
button.ui.button--reset:hover i.icon::after {
  border-color: var(--c-primary);
}
button.ui.button--reset:hover > i.icon {
  transform: rotate(360deg);
}
button.ui.button--success {
  background: var(--c-success);
  color: var(--c-white);
}
button.ui.button--dark,
.button--dark button.ui.button {
  background: #000;
  color: var(--c-white);
}
.ui.buttons .button:first-child {
  border: 1px solid var(--c-primary);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ui.buttons .ui.button.primary {
  color: var(--c-white);
  background: var(--c-primary);
}
button.ui.button.button--grid {
  color: var(--c-secondary-dark);
  padding: 1.125rem 2rem;
  background: transparent;
  border: 1px solid var(--c-primary);
  border-radius: 5px;
}
button.ui.button.button--grid > i.icon {
  font-size: 1.5rem;
  margin-right: 1rem !important;
}
button.ui.button.button--grid:hover,
button.ui.button.button--grid:focus,
button.ui.button.button--grid:active {
  color: var(--c-secondary);
  background-color: rgba(var(--c-primary-rgb), 10%);
}
.ui.button.button--grid {
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.2em;
  text-align: left;
  display: flex;
}
button.ui.logout--button,
.logout--button button.ui.button {
  background: transparent;
}
button.ui.logout--button:hover,
.logout--button button.ui.button:hover,
button.ui.logout--button:focus,
.logout--button button.ui.button:focus,
button.ui.logout--button:active,
.logout--button button.ui.button:active {
  background: transparent;
}
button.ui.logout--button i,
.logout--button button.ui.button i {
  color: var(--c-secondary);
}
.login--button {
  width: 100%;
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary-dark) 100%);
  border-radius: 4px;
  cursor: pointer;
}
.login--button > .ui.button {
  padding: 18px 0;
  background: transparent;
  width: 100%;
  height: 100%;
}
.login--button:hover,
.login--button:focus,
.login--button:active {
  animation: on-hover-button 0.2s linear;
  background: linear-gradient(var(--gradient-deg), var(--c-secondary-light) 0%, var(--c-secondary-dark) 100%);
}
.login--button:hover > .ui.button,
.login--button:focus > .ui.button,
.login--button:active > .ui.button {
  background: transparent;
}
form.ui.form .field,
form {
  margin: 0 0 0.35rem;
}
form.ui.form .field label,
form label {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: var(--primary-font);
}
form.ui.form .field label a,
form label a {
  color: var(--c-primary);
  font-weight: 500;
  text-decoration: underline;
}
form.ui.form .field input,
form input,
form.ui.form .field textarea,
form textarea,
form.ui.form .field .selection.dropdown,
form .selection.dropdown {
  border-color: var(--c-grey-light);
  border-radius: 0.125rem;
  padding: 0.65rem 1rem;
  font-weight: 500;
  font-family: var(--primary-font);
  width: 100%;
}
form.ui.form .field input::placeholder,
form input::placeholder,
form.ui.form .field textarea::placeholder,
form textarea::placeholder,
form.ui.form .field .selection.dropdown::placeholder,
form .selection.dropdown::placeholder {
  color: var(--c-grey-mid);
}
form.ui.form .field .selection.dropdown,
form .selection.dropdown {
  width: 100%;
}
form.ui.form .field .selection.dropdown .text,
form .selection.dropdown .text {
  line-height: initial;
}
form.ui.form .field .orderBy,
form .orderBy {
  min-width: 285px;
}
form.ui.form .field .userType,
form .userType {
  min-width: 335px;
}
form.ui.form .field .ui.checkbox:not(.toggle) input,
form .ui.checkbox:not(.toggle) input {
  height: 1.5rem;
  width: 1.5rem;
}
form.ui.form .field .ui.checkbox:not(.toggle) label,
form .ui.checkbox:not(.toggle) label {
  text-align: left;
  margin: 0;
  padding-left: 2.5rem;
}
form.ui.form .field .ui.checkbox:not(.toggle) label:before,
form .ui.checkbox:not(.toggle) label:before {
  height: 1.5rem;
  width: 1.5rem;
  border-color: var(--c-grey-mid);
}
form.ui.form .field .ui.checkbox:not(.toggle).checked label:before,
form .ui.checkbox:not(.toggle).checked label:before {
  background: var(--c-success);
  border-color: var(--c-success);
}
form.ui.form .field .ui.checkbox:not(.toggle).checked label:after,
form .ui.checkbox:not(.toggle).checked label:after {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  background-size: 80%;
}
form.ui.form .field .ui.checkbox.toggle label:before,
form .ui.checkbox.toggle label:before {
  width: 4rem;
  height: 2rem;
  top: -0.25rem;
}
form.ui.form .field .ui.checkbox.toggle label:after,
form .ui.checkbox.toggle label:after {
  width: 1.625rem;
  height: 1.625rem;
  box-shadow: none;
  background: var(--c-white);
  top: -0.05rem;
  left: 0.2rem;
}
form.ui.form .field .ui.checkbox.toggle input:checked ~ label:before,
form .ui.checkbox.toggle input:checked ~ label:before {
  background-color: var(--c-success) !important;
}
form.ui.form .field .ui.checkbox.toggle input:checked ~ label:after,
form .ui.checkbox.toggle input:checked ~ label:after {
  left: 2.2rem;
}
form.ui.form .field + span,
form + span {
  font-size: 0.75rem;
}
form.ui.form .field.error + span,
form.error + span {
  color: var(--c-error);
}
form.ui.form .field div ~ span,
form div ~ span {
  font-size: 0.75rem;
  margin-top: 4px;
}
form.ui.form .field .error ~ span,
form .error ~ span {
  color: var(--c-error);
}
div[role="combobox"],
.ui.multiple.dropdown {
  display: flex;
  flex-wrap: wrap;
}
div[role="combobox"] > .ui.label,
.ui.multiple.dropdown > .ui.label {
  background-color: var(--c-primary);
  color: var(--c-white);
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1rem;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.8rem 0.5rem 1.5rem;
  width: fit-content;
}
div[role="combobox"] > .ui.label > i,
.ui.multiple.dropdown > .ui.label > i {
  color: var(--c-primary);
  flex: 0 0 25px;
  height: 25px;
  position: relative;
  padding: 10px;
  background: var(--c-white);
  opacity: 1;
  border-radius: 50%;
}
div[role="combobox"] > .ui.label > i::before,
.ui.multiple.dropdown > .ui.label > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dimmer .loader.text .inverted.header {
  font-family: var(--secondary-font);
}
.ui.page.modals {
  background-color: rgba(0, 0, 0, 0.3);
}
.ui.page.modals .modal {
  box-shadow: none;
}
.ui.page.modals .modal .close.icon {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 400;
  top: 1.7rem;
  right: 0.8rem;
  color: var(--c-primary);
}
.ui.page.modals .modal > div.header {
  font-weight: 500;
  font-size: 1.5rem;
  background-color: transparent;
  padding: 1.5rem 2rem;
}
.ui.page.modals .modal > div.header h3 {
  color: var(--c-primary);
}
.ui.page.modals .modal .content {
  padding: 2rem;
}
.ui.page.modals .modal .content h3.header {
  font-size: 1.5rem;
  font-weight: 500;
}
.ui.page.modals .modal > div.noPaddingTop.content {
  padding-top: 0;
}
.ui.page.modals .modal .actions button {
  margin: 0 0 0 1rem;
}
.ui.page.modals figure.table {
  margin: 0;
}
.ui.page.modals figure.table table {
  border-collapse: collapse;
}
.ui.page.modals figure.table table tr {
  border: 1px solid var(--c-grey-mid);
}
.ui.page.modals figure.table table tr > td:first-of-type {
  border-right: 1px solid var(--c-grey-mid);
  font-weight: 600;
}
.ui.page.modals figure.table table td {
  padding: 10px;
}
#app .ui.sidebar.sidebar--left {
  visibility: visible;
  display: flex;
  flex-direction: column;
  background: var(--c-background);
  min-width: 5rem;
  padding-top: calc(6.25rem + 1rem);
}
@media screen and (max-width: 52rem) {
  #app .ui.sidebar.sidebar--left {
    min-width: auto;
  }
}
#app .ui.sidebar.sidebar--left .sidebar__item {
  text-align: center;
  padding: 0.75rem 1.5rem;
}
@media screen and (max-width: 52rem) {
  #app .ui.sidebar.sidebar--left .sidebar__item {
    padding: 0.75rem 1rem;
  }
}
#app .ui.sidebar.sidebar--left .sidebar__item .icon,
#app .ui.sidebar.sidebar--left .sidebar__item .ui.image {
  color: var(--c-secondary);
  margin: 0 auto;
}
#app .ui.sidebar.sidebar--left .sidebar__item:hover .icon,
#app .ui.sidebar.sidebar--left .sidebar__item:hover .ui.image {
  color: var(--c-primary);
}
#app .ui.sidebar.sidebar--left .sidebar__item.active {
  background: var(--c-secondary);
}
#app .ui.sidebar.sidebar--left .sidebar__item.active .icon,
#app .ui.sidebar.sidebar--left .sidebar__item.active .ui.image {
  color: var(--c-background);
}
#app .filterSidebar.filterSidebar--left {
  visibility: visible;
  display: flex;
  flex-direction: column;
  background: var(--c-background);
  width: 25rem;
  padding: 2.5rem;
}
#app .filterSidebar.filterSidebar--left .ui.grid {
  position: relative;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row {
  display: flex;
  flex-direction: column;
  position: relative;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs {
  padding: 0.58035714em 0.875em;
  background: var(--c-white);
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: 14em;
  border-radius: 0.25rem;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs .field {
  position: relative;
  margin-top: 2rem;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs .field label {
  color: var(--c-grey-mid);
  font-size: 1rem;
  line-height: 1.214em;
  position: absolute;
  z-index: 2;
  top: 0.7rem;
  left: 1rem;
  margin: 0 0 0 0;
  width: calc(100% - 1.3rem);
  pointer-events: none;
  transition: all 0.3s ease-out;
  padding-left: 0;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs .field:first-child {
  margin-bottom: 0.875em;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs .field > .ui.input {
  display: block;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs .field span {
  line-height: 1.2em;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs div.filled .field label {
  top: -22px;
  left: 0.5rem;
  color: var(--c-primary);
  font-size: 0.875rem;
  line-height: 17px;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.covered-amount > .covered-amount-inputs div.filled span {
  line-height: 13px;
  border: 0;
  margin: 0;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.filter__buttons {
  padding: 1rem 0 1.5rem 0;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 6.25rem;
  background: var(--c-background);
  z-index: 3;
  border-bottom: 1px solid var(--c-grey-mid);
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.filter__buttons:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-box-shadow: 0px 0px 1px 0.5px var(--c-grey-mid);
  -moz-box-shadow: 0px 0px 1px 0.5px var(--c-grey-mid);
  box-shadow: 0px 0px 1px 0.5px var(--c-grey-mid);
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.filter__buttons + .row {
  padding-top: 2rem;
}
#app .filterSidebar.filterSidebar--left .ui.grid .row.filter__buttons .column {
  padding: 0;
  display: inherit;
  align-items: inherit;
}
#app .filterSidebar.filterSidebar--left .ui.grid label {
  color: var(--c-primary);
  font-family: var(--secondary-font);
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
#app .filterSidebar.filterSidebar--left .ui.grid .ui.input {
  width: 100%;
}
#app .filterSidebar.filterSidebar--left .ui.grid .ui.input input {
  font-family: var(--primary-font);
}
#app .filterSidebar.filterSidebar--left .ui.grid .ui.input input::placeholder {
  color: var(--c-grey-mid);
}
#app .filterSidebar.filterSidebar--left .ui.grid .row:first-child label {
  font-size: 1.5rem;
  display: inline-block;
}
#app .filterSidebar.filterSidebar--left .ui.grid button.ui.button--underline {
  margin-left: 1rem;
}
#app .filterSidebar.filterSidebar--left .ui.grid button.ui.button--underline > .button--text::after {
  left: 5px;
}
@media screen and (max-width: 48rem) {
  #app .filterSidebar.filterSidebar--left {
    min-width: auto;
  }
}
table.ui.table {
  margin: 0;
  background: transparent;
  border: 0;
  border-collapse: collapse;
}
table.ui.table thead th {
  background: var(--c-grey-norm);
  color: var(--c-white);
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 48rem) {
  table.ui.table thead th {
    padding: 0.5rem 1rem;
  }
}
table.ui.table thead th div label {
  background: var(--c-grey-norm);
  color: var(--c-white) !important;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}
table.ui.table tbody {
  background: var(--c-white);
}
table.ui.table tbody td {
  padding: 1.5rem 2.5rem;
}
@media screen and (max-width: 48rem) {
  table.ui.table tbody td {
    padding: 1rem 1.5rem;
  }
}
table.ui.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}
table.ui.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
  display: flex;
  justify-content: flex-end;
}
table.ui.table tbody tr:last-child td:last-child > div {
  text-decoration: underline;
  cursor: pointer;
}
table.ui.table tfoot th {
  background: transparent;
  padding: 2rem 0 0;
  border: 0;
}
.tooltip {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.tooltip--right {
  justify-content: flex-start;
}
.tooltip--right > img {
  margin-inline-start: 0.5em;
}
.tooltip--left {
  justify-content: flex-start;
}
.tooltip--left > img {
  margin-inline-end: 0.5em;
}
.tooltip > span > span {
  display: flex;
  align-items: center;
}
.tooltip > span > span .ui.header:first-child {
  margin-top: 0;
}
.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: var(--c-secondary);
  color: var(--c-white);
  box-shadow: 1px 1px 2px 1px rgba(97, 97, 97, 0.9);
  font-size: 10px;
  font-family: var(--primary-font);
}
.MuiTooltip-popper .MuiTooltip-tooltip .MuiTooltip-arrow {
  color: var(--c-secondary);
}
.MuiTooltip-popper.isDisabled .MuiTooltip-tooltip {
  background: var(--c-error);
}
.MuiTooltip-popper.isDisabled .MuiTooltip-tooltip .MuiTooltip-arrow {
  color: var(--c-error);
}
.MuiTooltip-popper.isTooltip .MuiTooltip-tooltip {
  background: var(--c-primary);
}
.MuiTooltip-popper.isTooltip .MuiTooltip-tooltip .MuiTooltip-arrow {
  color: var(--c-primary);
}
.MuiTooltip-popper.isGrey .MuiTooltip-tooltip {
  background: var(--c-grey-dark);
}
.MuiTooltip-popper.isGrey .MuiTooltip-tooltip .MuiTooltip-arrow {
  color: var(--c-grey-dark);
}
#app .tabular.menu {
  border: 0;
}
#app .tabular.menu .item {
  border: 0;
  border-radius: 0.5rem 0.5rem 0 0 !important;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 0.875rem;
  background: var(--c-white);
}
#app .tabular.menu .item:not(.active) {
  opacity: 0.65;
}
#app .active.tab {
  border: 0;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: 1.5rem;
}
@media screen and (max-width: 48rem) {
  #app .active.tab {
    padding: 1rem;
  }
}
#app .active.tab h3 {
  font-size: 1.375rem;
  font-weight: 500;
}
.MuiFormControl-root {
  width: 100% ;
  border-radius: 0.125rem !important;
  border: 1px solid #C4C4C4 !important;
}
.MuiFormControl-root .MuiInputBase-adornedEnd.MuiInputBase-formControl input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
  padding-left: 15px !important;
  width: 100%;
}
.MuiFormControl-root div.ui.label.dropdown[role='listbox'],
.MuiFormControl-root div:not(.select__input) > input[type='number'],
.MuiFormControl-root div:not(.select__input) > input[type='text'],
.MuiFormControl-root .select__control {
  border: 0 !important;
}
.fc.fc-theme-standard .fc-toolbar-title {
  font-size: 1.75rem;
  font-weight: 500;
}
@media screen and (max-width: 48rem) {
  .fc.fc-theme-standard .fc-toolbar-title {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 48rem) {
  .fc.fc-theme-standard .fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }
}
.fc.fc-theme-standard .fc-col-header-cell-cushion {
  color: #000;
  font-weight: 500;
}
.fc.fc-theme-standard .fc-bg-event {
  background-color: var(--c-success) !important;
  opacity: 0.75;
}
.fc.fc-theme-standard .fc-v-event {
  background: var(--c-primary-light);
  border-color: var(--c-primary-light);
  border-radius: 0;
  box-shadow: 0 0 0 1px var(--c-primary);
}
.ui.pagination.menu {
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: var(--primary-font);
  font-size: 0.875rem;
}
.ui.pagination.menu .item {
  margin-left: 0.5rem;
  border-radius: 2px;
  display: inline-block;
  padding: 0;
  background: var(--c-white);
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  min-width: unset;
}
.ui.pagination.menu .item:last-child {
  border-radius: 2px;
}
.ui.pagination.menu .item:before {
  content: none;
}
.ui.pagination.menu .item:hover,
.ui.pagination.menu .item.active {
  background: var(--c-secondary);
  color: var(--c-white);
  font-weight: 700;
  padding-top: 0;
}
body.list table.ui.table {
  border-collapse: separate;
}
body.list table.ui.table thead {
  display: none;
}
body.list table.ui.table tbody tr {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid var(--c-grey-light);
  border-radius: 4px;
  height: 100px;
}
body.list table.ui.table tbody tr:not(:last-child) {
  margin-bottom: 16px;
}
@media screen and (max-width: 48rem) {
  body.list table.ui.table tbody tr {
    height: 150px;
  }
}
body.list table.ui.table tbody td {
  font-family: var(--primary-font);
  font-weight: 600;
  position: relative;
  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
}
body.list table.ui.table tbody td:nth-child(1) {
  color: var(--c-primary);
  font-size: 1.5rem;
  flex: 0 0 90%;
  justify-content: flex-start;
  line-height: 1.1em;
  max-height: 55%;
  padding: 0 2.5rem;
  margin-top: auto;
  margin-bottom: auto;
}
@media screen and (max-width: 48rem) {
  body.list table.ui.table tbody td:nth-child(1) {
    line-height: 1.3em;
  }
}
body.list table.ui.table tbody td:nth-child(1) > .list_title {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 48rem) {
  body.list table.ui.table tbody td:nth-child(1) > .list_title {
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
  }
}
@media screen and (max-width: 48rem) {
  body.list table.ui.table tbody td:nth-child(1) {
    font-size: 1.125rem;
  }
}
body.list table.ui.table tbody td:nth-child(2) {
  font-size: 1.125rem;
  justify-content: flex-end;
  flex: 0 0 10%;
}
@media screen and (max-width: 48rem) {
  body.list table.ui.table tbody td:nth-child(2) {
    font-size: 0.875rem;
  }
}
body.list table.ui.table tbody td:nth-child(2) a {
  text-decoration: underline;
  color: #000;
  border-radius: 3px;
  margin: 0;
  flex: 0 0 100%;
  position: relative;
}
body.list table.ui.table tbody td:nth-child(2) i.icon {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  text-decoration: underline;
}
body.list table.ui.table tbody td:nth-child(2) i.icon::before {
  padding: 7px;
  border-radius: 2px;
  cursor: pointer;
}
body.list table.ui.table tbody td[colspan="2"] {
  justify-content: flex-end;
  flex: 0 0 100%;
}
div[class$="SectionBody"] {
  margin: 0 auto;
  padding: 2.5rem 4rem 6rem 4rem;
}
@media (min-width: 80rem) {
  div[class$="SectionBody"] {
    max-width: 70rem;
  }
}
@media screen and (max-width: 48rem) {
  div[class$="SectionBody"] {
    padding: 3rem 2rem 4rem 2rem;
  }
}
@media screen and (max-width: 48rem) {
  div[class$="SectionBody"] .contentGrid {
    overflow-x: scroll;
    padding-bottom: 2rem;
  }
}
#app .filterEdit__content {
  position: relative;
}
#app .filterEditSectionBody {
  position: relative;
  padding: 2.5rem 4rem 10rem 4rem;
}
#app .filterEditSectionBody .reloader {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  transform: translateY(-50%);
}
#app .rst__tree {
  position: relative;
  width: 100%;
}
#app .rst__tree .rst__node:first-child .rst__nodeContent {
  width: 80%;
  max-width: 700px;
}
#app .rst__tree .rst__node:first-child .rst__nodeContent .rst__rowWrapper .rst__row {
  border: none;
}
#app .rst__tree .rst__node:first-child .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents {
  background-color: var(--c-primary);
}
#app .rst__tree .rst__node:first-child .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle {
  color: var(--c-white);
}
#app .rst__tree .rst__node:first-child .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field .ui.input input {
  background: transparent;
  color: var(--c-white);
  font-weight: 600;
}
#app .rst__tree .rst__node:first-child .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar .rst__toolbarButton button.ui.button {
  color: var(--c-primary);
  background: var(--c-primary-light);
}
#app .rst__tree .rst__node:first-child .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar .rst__toolbarButton .ui.button > .icon:not(.button) {
  opacity: 1;
}
#app .rst__tree .rst__node .rst__nodeContent {
  width: 80%;
  max-width: 1000px;
  min-width: 500px;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row {
  border: 1px solid var(--c-grey-mid);
  position: relative;
  border-radius: 2px;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__moveHandle {
  position: relative;
  background: transparent;
  box-shadow: none;
  border: none;
  flex: 0 0 40px;
  width: 40px;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__moveHandle::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 10px;
  background: url('data:image/svg+xml,%3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 400"%3E%3Cpath fill="%23272B30" d=" M16 316H432C440.837 316 448 323.163 448 332V372C448 380.837 440.837 388 432 388H16C7.163 388 0 380.837 0 372V332C0 323.163 7.163 316 16 316zM16 156H432C440.837 156 448 163.163 448 172V212C448 220.837 440.837 228 432 228H16C7.163 228 0 220.837 0 212V172C0 163.163 7.163 156 16 156zM16 -4H432C440.837 -4 448 3.163 448 12V52C448 60.837 440.837 68 432 68H16C7.163 68 0 60.837 0 52V12C0 3.163 7.163 -4 16 -4z" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
  padding: 2px;
  display: flex;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents {
  position: relative;
  box-shadow: none;
  flex: 1;
  border: none;
  display: flex;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel {
  position: relative;
  flex: 1;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle {
  padding: 3px 0;
  height: 100%;
  flex: 0 0 100%;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field {
  position: relative;
  height: 100%;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field .ui.input input {
  position: relative;
  border: none;
  pointer-events: none;
  flex: 1;
  padding: 0.5rem 0.6rem;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field .ui.input input.isActive {
  pointer-events: all;
  border: 1px solid var(--c-grey-light);
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field.error {
  margin: 0;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field.error .ui.input input.isActive {
  border-color: var(--c-error);
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle .field.error ~ span {
  font-family: var(--primary-font);
  font-weight: normal;
  position: absolute;
  bottom: 50%;
  left: 0.6rem;
  margin-top: 0;
  line-height: 1.5em;
  transform: translateY(50%);
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar .rst__toolbarButton {
  height: 100%;
  padding: 2px 0;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar .rst__toolbarButton button.ui.button {
  height: 100%;
  width: 2.5rem;
  color: var(--c-secondary);
  background: var(--c-background);
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar .rst__toolbarButton button.ui.button:not(:first-child) {
  margin-left: 3px;
}
#app .rst__tree .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowToolbar .rst__toolbarButton button.ui.button.isDisabled {
  background: #e0b4b4;
  color: var(--c-error);
  opacity: 0.6;
  cursor: not-allowed;
}
#app .rst__rowLandingPad,
#app .rst__rowCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: 1px black solid;
}
#app .rst__rowLandingPad > *,
#app .rst__rowCancelPad > * {
  opacity: 0 !important;
}
#app .rst__rowLandingPad::before,
#app .rst__rowCancelPad::before {
  background-color: transparent;
  border: 1px dashed var(--c-grey-mid);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
#app .rst__collapseButton,
#app .rst__expandButton {
  appearance: none;
  border: none;
  position: absolute;
  border-radius: 3px;
  background: var(--c-primary);
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
#app .rst__collapseButton::before,
#app .rst__expandButton::before,
#app .rst__collapseButton::after,
#app .rst__expandButton::after {
  content: "";
  width: 12px;
  height: 1px;
  background: var(--c-white);
  top: 50%;
  left: 50%;
  position: absolute;
  transform-origin: center;
}
#app .rst__collapseButton::after,
#app .rst__collapseButton::before {
  transform: translate(-50%, -1px);
}
#app .rst__expandButton::before {
  transform: translate(-50%, -1px);
}
#app .rst__expandButton::after {
  right: 0;
  left: auto;
  transform: rotate(90deg) translate(-0.5px, 2px);
}
#app .rst__collapseButton:focus,
#app .rst__expandButton:focus {
  outline: none;
  box-shadow: 1px 1px 1px 1px rgba(131, 146, 161, 0.24);
}
#app .rst__collapseButton:hover:not(:active),
#app .rst__expandButton:hover:not(:active) {
  height: 19px;
  width: 19px;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px rgba(131, 146, 161, 0.24);
}
#app .rst__expandButton:hover:not(:active)::after {
  transform: rotate(90deg) translate(-0.5px, 3.5px);
}
#app .rst__lineBlock::after,
#app .rst__lineChildren::after,
#app .rst__lineHalfHorizontalRight::before::before {
  background-color: var(--c-grey-dark);
}
.ui.modal.active.tooltip__edit div.header {
  color: var(--c-primary);
}
.ui.modal.active.tooltip__edit .content {
  display: flex;
  flex-direction: column;
}
.ui.modal.active.tooltip__edit .content label {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: var(--secondary-font);
}
.ui.modal.active.tooltip__edit .content div#editor {
  margin-top: 1rem;
}
.ui.modal.active.tooltip__edit .content div#editor .ck.ck-editor .ck.ck-editor__main .ck-editor__editable {
  height: 12rem;
}
.ui.modal.active.tooltip__edit .content button.ui.button {
  margin-top: 1rem;
  align-self: flex-end;
  margin-right: 10px;
  float: right;
}
.ui.modal.active.tooltip__edit .content button.ui.button i.icon {
  margin-right: 6px;
}
#app .access {
  height: 100%;
  width: 100%;
  background-position: right center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 48rem) {
  #app .access {
    background-position: center -5%;
    background-size: contain;
  }
}
#app .access .bg-image {
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 0;
}
#app .access__container {
  width: 35rem;
  padding: 2rem;
  background: var(--c-white);
  filter: drop-shadow(6px 4px 24px rgba(131, 146, 161, 0.24));
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 48rem) {
  #app .access__container {
    padding: 2rem 0 1.5rem;
    height: auto;
    width: 90%;
    margin: 3rem auto 0;
  }
}
#app .access__container__title {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 2rem 0rem;
  color: var(--c-primary);
}
@media (max-width: 48rem) {
  #app .access__container__title {
    margin: 2rem 0 1rem;
  }
}
#app .access h1.header[class*="__title"] {
  font-weight: 500;
}
#app .access h1.header[class*="__title"] span {
  font-weight: 700;
}
#app .access .login__form {
  padding-top: 2rem;
}
@media screen and (max-width: 48rem) {
  #app .access .login__form {
    margin: 0 1.25rem;
    padding-top: 0;
  }
}
#app .access .login__form input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--c-grey-light);
  padding: 0.5rem 0 0.75rem;
  font-family: var(--primary-font);
}
#app .access .login__form input:focus {
  border-color: var(--c-secondary);
}
#app .access .login__form .user--blocked {
  color: var(--c-error);
  font-weight: 700;
}
#app .access .login__form .login__password-row {
  padding: 0.5rem 0 2rem;
}
@media screen and (max-width: 48rem) {
  #app .access .login__form .login__password-row {
    padding: 0.5rem 0;
  }
}
#app .access .login__form .login__password-row .login__password-recovery {
  margin-left: auto;
  text-align: right;
}
@media screen and (max-width: 48rem) {
  #app .access .login__form .login__password-row .login__password-recovery {
    margin-bottom: 1rem;
  }
}
#app .access .login__form .login__password-row .login__password-recovery .user--blocked {
  pointer-events: none;
  cursor: default;
}
#app .access .login__form .login__password-row .login__password-recovery a {
  font-family: var(--primary-font);
  font-size: 0.875rem;
  color: var(--c-secondary);
}
#app .standardlayout__container {
  width: 100%;
  min-height: 100vh;
  padding: 6.25rem 0 0 5rem;
  background: var(--c-white) no-repeat center center;
  background-size: cover;
  padding-top: 6.25rem;
  position: relative;
}
@media screen and (max-width: 52rem) {
  #app .standardlayout__container {
    padding: 6.25rem 0 0 3.25rem;
  }
}
#app .MuiBreadcrumbs-root {
  position: relative;
  padding-top: 1rem;
  padding-left: 2rem;
}
#app .MuiBreadcrumbs-root > ol {
  font-weight: 500;
  font-size: 15px;
}
#app .MuiBreadcrumbs-root > ol .MuiBreadcrumbs-li a {
  color: var(--c-primary);
  position: relative;
}
#app .MuiBreadcrumbs-root > ol .MuiBreadcrumbs-li a::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: transparent;
  width: 0;
  height: 100%;
  border-bottom: 1px solid var(--c-primary);
}
#app .MuiBreadcrumbs-root > ol .MuiBreadcrumbs-li a:hover {
  text-decoration: none;
}
#app .MuiBreadcrumbs-root > ol .MuiBreadcrumbs-li a:hover::before {
  width: 100%;
  transition: width 0.3s linear;
}
#app .MuiBreadcrumbs-root > ol li.MuiBreadcrumbs-li {
  color: var(--c-grey-mid);
}
#app div.scroll__container {
  min-height: calc(100vh - (6.25rem + 146px));
}
#app .elencoBandilayout__container {
  width: 100%;
  height: 100%;
  padding-top: 6.25rem;
  min-height: 100vh;
  background: var(--c-white) no-repeat center center;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 48rem) {
  #app .elencoBandilayout__container {
    margin: 6.25rem 0 0 0;
  }
}
#app .elencoBandilayout__container .wave_bg {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  z-index: 0;
}
#app .elencoBandilayout__container .scroll__container {
  z-index: 10;
}
#app .elencoBandilayout__container .scroll__container > * {
  z-index: inherit;
}
#app .elencoBandilayout__container .scroll__container > .subHeader {
  width: 100%;
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}
#app .elencoBandilayout__container .scroll__container > .subHeader > * {
  z-index: inherit;
}
@media (max-width: 48rem) {
  #app .elencoBandilayout__container .scroll__container > .subHeader {
    padding: 2rem;
  }
}
#app .elencoBandilayout__container .scroll__container > .subHeader > h1 {
  color: var(--c-primary);
  font-size: 3rem;
  line-height: 1.2em;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 40rem;
}
@media (max-width: 48rem) {
  #app .elencoBandilayout__container .scroll__container > .subHeader > h1 {
    font-size: 1.75rem;
  }
}
#app .elencoBandilayout__container .scroll__container > .subHeader > h4 {
  margin-top: 0;
  font-family: var(--primary-font);
  font-size: 1.25rem;
  line-height: 1.4em;
  font-weight: 400;
  text-align: center;
}
#app .elencoBandilayout__container .scroll__container .announcementMain__container {
  width: 100%;
  position: relative;
  display: flex;
}
@media (max-width: 48rem) {
  #app .elencoBandilayout__container .scroll__container .announcementMain__container {
    flex-direction: column;
  }
}
#app .elencoBandilayout__container .scroll__container .announcementMain__container .announcementGrid__container {
  width: 100%;
  padding: 0 4rem;
}
@media (max-width: 48rem) {
  #app .elencoBandilayout__container .scroll__container .announcementMain__container .announcementGrid__container {
    padding: 2rem 1rem 0;
  }
}
#app .register__button--submit {
  margin-top: 1em;
}
#app .register__button--to-dashboard {
  margin-top: 1em;
}
#app .register__email-icon {
  display: flex;
  justify-content: center;
}
#app .password-framework {
  z-index: 5;
  color: var(--c-primary);
  max-width: 90vw;
  background: var(--c-white);
  padding: 2rem;
  opacity: 1;
  border-radius: 4px;
  filter: drop-shadow(6px 4px 24px rgba(131, 146, 161, 0.24));
}
#app .password-framework .password-framework__container {
  background: transparent;
}
#app .password-framework .password-framework__container .ui.segment {
  background: transparent;
  border: none;
  box-shadow: unset;
  padding: 0;
}
#app .password-framework .ui.grid .row.token--error--message {
  padding: 0;
  margin-bottom: 1rem;
}
#app .password-framework img {
  margin-bottom: 1rem;
}
#app .password-framework button.ui.button div {
  pointer-events: all;
}
#app .password-framework button.ui.button div a {
  color: var(--c-white);
}
#app .password-framework button.ui.button.button--submit,
#app .password-framework button.ui.button.button--to-dashboard {
  margin-top: 1.5rem;
}
@media screen and (max-width: 48rem) {
  #app .password-framework form.ui.form .grid > .row > .column:not(:first-child) {
    margin-top: 1rem;
  }
}
div.homepage-container div.ui.center.aligned.segment {
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
}
div.homepage-container div.ui.center.aligned.segment .homeHeader {
  max-width: 50rem;
  margin: 0 auto;
  padding: 10vh 0;
}
@media (max-width: 48rem) {
  div.homepage-container div.ui.center.aligned.segment .homeHeader {
    padding: 3vh 0;
    margin: 0 1rem;
  }
}
div.homepage-container div.ui.center.aligned.segment .homeHeader h1 {
  font-size: 2.5rem;
  margin-bottom: 0.75rem;
  color: var(--c-primary);
}
@media (max-width: 48rem) {
  div.homepage-container div.ui.center.aligned.segment .homeHeader h1 {
    font-size: 1.75rem;
  }
}
div.homepage-container div.ui.center.aligned.segment .homeHeader p {
  margin: 0;
  font-family: var(--primary-font);
  font-size: 1.125rem;
  font-weight: 500;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles {
  max-width: 80rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
}
@media (max-width: 48rem) {
  div.homepage-container div.ui.center.aligned.segment .homeTiles {
    flex-wrap: wrap;
    margin: 0 1rem;
    padding: 0;
  }
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a {
  color: var(--c-grey-dark);
  border: 1px solid var(--c-secondary);
  border-radius: 0.5rem;
  transition: transform 0.2s linear;
  flex: 0 0 20rem;
}
@media (max-width: 48rem) {
  div.homepage-container div.ui.center.aligned.segment .homeTiles > a {
    flex: 0 1 auto;
    margin-bottom: 2rem;
  }
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a:not(:last-child) {
  margin-right: 6rem;
}
@media (max-width: 48rem) {
  div.homepage-container div.ui.center.aligned.segment .homeTiles > a:not(:last-child) {
    margin-right: 0;
  }
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a .tile {
  margin: 0 !important;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: none;
  border: none;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a .tile > i.icon {
  font-size: 1.75rem;
  color: var(--c-secondary);
  margin: 0;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a .tile .tileTitle {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: var(--c-primary);
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a .tile .tileDescription {
  font-family: var(--primary-font);
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles > a:hover {
  transform: scale(1.05, 1.05);
  box-shadow: 6px 4px 24px rgba(var(--c-secondary-rgb), 0.24);
  transition: all 0.2s linear;
}
div.homepage-container div.ui.center.aligned.segment .homeMessage {
  font-size: 0.875rem;
  font-weight: 500;
  padding-top: 4rem;
}
div.homepage-container div.ui.center.aligned.segment .homeMessage a {
  font-weight: 700;
  text-decoration: underline;
}
#app .customer-container .customerSectionBody {
  margin: 0 auto;
  padding: 2.5rem 6rem 4rem;
}
@media (max-width: 105rem) {
  #app .customer-container .customerSectionBody {
    max-width: 70rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .customer-container .customerSectionBody {
    padding: 2.5rem 1rem 4rem;
  }
}
#app .customer-container .customerSectionBody .contentGrid div table tbody tr {
  background: var(--c-background);
  border: 5px solid var(--c-grey-light);
}
@media screen and (max-width: 48rem) {
  #app .customer-container .customerSectionBody .contentGrid {
    overflow-x: scroll;
    padding-bottom: 2rem;
  }
}
#app .customer-container .customerSectionActions {
  padding-top: 1.5rem;
  height: auto;
}
#app .customer-container .customerSectionActions .position--left {
  float: left;
}
#app .customer-container .customerSectionActions .position--right {
  float: right;
}
body.customer-list table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
body.customer-list table.ui.table thead {
  display: none;
}
body.customer-list table.ui.table tbody td {
  border: 0;
}
body.customer-list table.ui.table tbody td:first-child {
  text-align: left;
  color: var(--c-primary);
  font-size: 1.5rem;
  font-weight: 700;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
body.customer-list table.ui.table tbody td:last-child {
  text-align: right;
  font-size: 0.875rem;
  font-weight: 600;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
body.customer-list table.ui.table tbody td:last-child a {
  text-decoration: underline;
  color: #000;
}
.dashboard-container .dashboard__content .dashboardHeader {
  padding: 2rem;
  display: flex;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid {
  margin: 0;
  width: 100%;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column {
  padding: 0;
  display: flex;
  align-items: center;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column i {
  color: #8098b2;
  margin-right: 1rem;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column h1 {
  font-weight: 500;
  margin-top: 0;
  line-height: 1em;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column:first-child {
  width: 35vw;
}
.dashboard-container .dashboard__content .dashboardBody {
  padding: 2em 4em;
}
.dashboard-container .dashboard__content .dashboardBody .row {
  display: flex;
  height: 100%;
  height: 500px;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid {
  display: flex;
  flex-flow: row wrap;
  width: 30%;
  justify-content: space-between;
  height: 100%;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat {
  width: 45%;
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  transition: transform 0.3s linear;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(1),
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(2) {
  margin-bottom: 2rem;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div {
  margin: 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > .statName {
  color: #8098b2;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > i {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 50%;
  color: #8098b2;
  margin: 0 0 15px 0;
  padding: 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > i::before {
  font-size: 1.5rem;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div h1 {
  font-size: 48px;
  line-height: 66px;
  margin: 5px 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(2),
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(3) {
  background: #f9f9f9;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(2) i,
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(3) i {
  background: white;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > .untilToday {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:hover {
  box-shadow: 4px 4px 8px rgba(7, 7, 7, 0.22);
  transform: scale(105%);
  transition: transform 0.5s linear;
}
.dashboard-container .dashboard__content .dashboardBody .chart {
  width: 70%;
  padding: 0 2em;
}
.dashboard-container .dashboard__content .dashboardBody #charts {
  height: 100%;
  background: white;
  border-radius: 4px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.dashboard-container .dashboard__content .dashboardBody #charts > * canvas {
  font-family: inherit;
}
@media screen and (max-width: 1500px) {
  .dashboard-container .dashboard__content .dashboardHeader {
    padding: 1rem;
  }
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader {
  margin: 0;
  padding: 1rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 48rem) {
  #app .elencoBandi__content .ui.dividing.header.elencoBandiHeader {
    flex-direction: column;
  }
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.form {
  margin: 0;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid var(--c-grey-light);
}
@media screen and (max-width: 48rem) {
  #app .elencoBandi__content .ui.dividing.header.elencoBandiHeader {
    padding: 1rem;
  }
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .totalItems > h4 {
  display: flex;
  font-family: var(--primary-font);
  font-size: 1rem;
  line-height: 1.2em;
  font-weight: 400;
}
@media (max-width: 48rem) {
  #app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .totalItems > h4 {
    padding-bottom: 1rem;
  }
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .totalItems > h4 > span {
  font-weight: 700;
  color: var(--c-primary);
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader > *:nth-child(2) {
  background: transparent;
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  position: relative;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader > *:nth-child(2) .ui.button.floating.labeled.dropdown {
  border-radius: 4px;
  padding: 15px 2rem;
  margin: 0;
  background: transparent;
  color: var(--c-primary);
  font-size: 1rem;
  font-weight: 600;
  z-index: 1;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader > *:nth-child(2) .ui.button.floating.labeled.dropdown > .divider.text {
  margin-right: 15px;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader > *:nth-child(2) .ui.button.floating.labeled.dropdown > i.icon {
  background: transparent;
  left: 10px;
  color: var(--c-secondary);
  font-size: 1.2rem;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader > *:nth-child(2) i.icon {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  top: 0;
  right: 7px;
  background: transparent;
  color: var(--c-primary);
  font-size: 1rem;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.button.labeled.dropdown {
  display: flex;
  height: 100%;
  font-family: var(--primary-font);
  font-size: 1rem;
  margin: 0;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.selection.dropdown {
  padding: 10px 20px;
  font-size: 1rem;
  width: 21em;
  font-family: var(--primary-font);
  line-height: 1.5em;
  display: flex;
  z-index: 3;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.selection.dropdown > div {
  color: var(--c-grey-mid);
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.selection.dropdown > .dropdown.icon {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .row {
  padding-top: 0;
  padding-bottom: 0;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.grid {
  margin: 0;
  align-items: center;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.grid .column {
  padding: 0;
}
@media screen and (max-width: 48rem) {
  #app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .ui.grid .column {
    width: 100% !important;
    padding-bottom: 1rem;
  }
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-right: 2rem;
}
@media screen and (max-width: 48rem) {
  #app .elencoBandi__content .ui.dividing.header.elencoBandiHeader h1 {
    font-size: 1.75rem;
  }
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .padded.grid .row {
  padding: 0;
  align-items: center;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .padded.grid .row .field {
  margin: 0 1.5rem 0 0;
  display: flex;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .padded.grid .elencoBandi__buttons {
  display: flex;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .padded.grid .elencoBandi__buttons > .button--primary {
  margin-right: 1.5rem;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .padded.grid .new_elencoBandi__buttons {
  display: flex;
  margin-left: 0;
}
#app .elencoBandi__content .ui.dividing.header.elencoBandiHeader .padded.grid .new_elencoBandi__buttons > .button--primary {
  margin-right: 1.5rem;
}
#app .elencoBandi__content .elencoBandiSectionBody {
  max-width: 90rem;
  margin: 0 auto;
  padding: 2.5rem 0;
}
@media (max-width: 105rem) {
  #app .elencoBandi__content .elencoBandiSectionBody {
    max-width: 70rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .elencoBandi__content .elencoBandiSectionBody {
    padding: 2.5rem 1rem 4rem;
  }
}
#app .elencoBandi__content .elencoBandiSectionBody .ui.pagination.menu {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
#app .elencoBandi__content .elencoBandiSectionActions {
  padding-top: 1.5rem;
  float: right;
}
#app {
  /* Change default semantic dropdown placeholder color */
}
#app .user-container form#userProfileForm .ui.checkbox.toggle label {
  padding-left: 5rem;
  display: flex;
  align-items: center;
}
#app .user-container form#userProfileForm .ui.checkbox.toggle label:before {
  background: var(--c-success) !important;
}
#app .user-container form#userProfileForm .ui.checkbox.toggle.inactive label:before {
  background: var(--c-grey-light) !important;
}
#app .user-container form#userProfileForm .ui.checkbox.toggle.checked label:before {
  background: var(--c-error) !important;
}
#app .user-container form#userProfileForm.view .ui.buttons.modify button.ui.button.button--grid.active {
  pointer-events: none;
  opacity: 0.75;
}
#app .user-container form#userProfileForm .ui.buttons.modify button.ui.button.button--grid {
  color: var(--c-grey-norm);
  border: 1px solid var(--c-grey-norm);
  opacity: 0.75;
  pointer-events: none;
}
#app .user-container form#userProfileForm .ui.buttons.modify button.ui.button.button--grid.active {
  color: var(--c-secondary-dark);
  border: 1px solid var(--c-primary);
  pointer-events: all;
  opacity: 1;
}
#app .user-container form#userProfileForm .ui.buttons.modify button.ui.button.button--grid.active.primary {
  color: var(--c-white);
  background: var(--c-primary);
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid > .row {
    padding: 0;
  }
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid > .row h3.header {
    margin: 2rem 0 1rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid > .row .field {
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid > .row .ui.buttons.modify {
    display: flex !important;
    flex-direction: column;
  }
}
#app .user-container form#userProfileForm .ui.grid > .row > [class*="left aligned"] {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid > .row:last-child > div {
    text-align: left;
    margin-bottom: 1rem;
  }
}
#app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons {
    display: flex;
    flex-direction: column;
  }
}
#app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons button {
  min-height: 9rem;
  padding: 1.125rem 2rem !important;
  background: transparent;
  border: 1px solid var(--c-grey-light);
  border-radius: 0.15rem;
  font-size: 1.125rem !important;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 48rem) {
  #app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons button {
    margin-bottom: 1rem;
  }
}
#app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons button i.icon {
  margin: 0;
  font-size: 2rem;
}
#app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons button.primary {
  border: 2px solid var(--c-primary);
  color: #000;
  font-weight: 600;
}
#app .user-container form#userProfileForm .ui.grid.company-type .ui.buttons button.primary i.icon {
  color: var(--c-primary);
}
#app .ui.dropdown:not(.button) > .default.text,
#app .ui.default.dropdown:not(.button) > .text {
  color: #989b9e;
}
.elencoBandi__content .elencoBandiSectionBody {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card {
  box-shadow: none;
  border: 1px solid var(--c-grey-light);
  color: var(--c-grey-dark);
  border-radius: 8px;
  margin: 0 0 2rem 0;
  width: calc(50% - 2rem);
}
@media (max-width: 64rem) {
  .elencoBandi__content .elencoBandiSectionBody .ui.card {
    width: 100%;
    flex: 0 0 100%;
  }
}
.elencoBandi__content .elencoBandiSectionBody .ui.card img.ui.image {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-left: 1rem;
  top: 10px;
  right: 10px;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card:nth-child(2n-1) {
  margin-right: 4rem;
}
@media (max-width: 64rem) {
  .elencoBandi__content .elencoBandiSectionBody .ui.card:nth-child(2n-1) {
    margin-right: 0;
  }
}
.elencoBandi__content .elencoBandiSectionBody .ui.card:hover {
  box-shadow: 6px 4px 24px rgba(131, 146, 161, 0.24);
  transform: scale(1.02, 1.02);
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content {
  border-top: none;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content:not(:first-child)::before {
  content: "";
  position: absolute;
  background: var(--c-grey-light);
  height: 1px;
  top: 0;
  left: 5%;
  width: 90%;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .header {
  color: var(--c-primary);
  font: var(--secondary-font);
  font-weight: bold;
  font-size: 18px;
  line-height: 1.32em;
  padding-right: 50px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .sub.header {
  text-transform: none;
  font-family: var(--primary-font);
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: var(--c-grey-dark);
  height: 20px;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .ui.sub.content {
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 1.4em;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .description {
  font-family: var(--primary-font);
  font-size: 14px;
  line-height: 1.4em;
  color: var(--c-grey-norm);
  margin-bottom: 2rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 80px;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .cta {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .cta > a {
  color: var(--c-secondary);
  font-family: var(--primary-font);
  font-weight: bold;
  text-decoration: underline;
}
.elencoBandi__content .elencoBandiSectionBody .ui.card > .content .cta > i.icon {
  color: var(--c-secondary);
}
.elencoBandi__content .elencoBandiSectionBodyNoResults {
  display: flex;
  justify-content: center;
  font-family: var(--primary-font);
  font-size: 0.875rem;
  line-height: 1.5em;
}
.elencoBandi__content .elencoBandiSectionBodyNoResults div {
  text-align: center;
}
.elencoBandi__content .elencoBandiSectionBodyNoResults div > img {
  margin: 3rem 0 1.5rem 0;
}
#app .announcementDetail__content {
  font-family: var(--primary-font);
  padding: 2rem 18rem 6rem 18rem;
}
@media screen and (max-width: 120rem) {
  #app .announcementDetail__content {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
@media screen and (max-width: 52rem) {
  #app .announcementDetail__content {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (max-width: 35rem) {
  #app .announcementDetail__content {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (max-width: 48rem) {
  #app .announcementDetail__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
#app .announcementDetail__content .announcementHeader {
  position: relative;
}
#app .announcementDetail__content .announcementHeader h1 {
  color: var(--c-primary);
  font-size: 3rem;
  line-height: 1.2em;
  margin-bottom: 3rem;
}
@media (max-width: 48rem) {
  #app .announcementDetail__content .announcementHeader h1 {
    font-size: 2rem;
    margin-bottom: 0;
  }
}
#app .announcementDetail__content .announcementHeader img.ui.image {
  width: 90px;
  height: 90px;
  position: absolute;
  top: -10px;
  right: -40px;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph + .announcementDetailParagraph {
  margin: 4rem 0 0 0;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  z-index: 900;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph > * {
  z-index: inherit;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .p-title {
  border-bottom: 1px solid var(--c-grey-light);
  width: 100%;
  color: var(--c-grey-dark);
  font-size: 2rem;
  margin-bottom: 0.7rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 48rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .p-title {
    font-size: 1.5rem;
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .p-title h1,
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .p-title h2 {
  font-family: var(--primary-font);
  font-weight: 600;
}
@media (max-width: 48rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .p-title h1 {
    font-size: 1.5rem;
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row {
  border-bottom: 1px solid var(--c-grey-light);
  width: 100%;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.no__border {
  border-bottom: none;
  font-size: 1.125rem;
  margin-top: 1rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row .row-title {
  font-size: 14px;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  color: var(--c-primary);
  font-weight: bold;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row .row-content {
  font-family: var(--primary-font);
  margin-bottom: 2rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row .row-content a {
  color: var(--c-secondary);
  text-decoration: underline;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments {
  display: flex;
  flex-direction: column;
  border-bottom: none;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem {
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--c-grey-light);
  padding: 1rem 2rem;
  flex: 0 0 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  max-height: 5rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem:not(:first-child) {
  margin-top: 1rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem > * {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem > i.icon.file.alternate {
  flex: 0 0 2rem;
  font-size: 2rem;
  color: #8f8f8f;
  margin: 0 15px 0 0;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info > .fileName,
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info .fileExtension {
  flex: 0 0 fit-content;
  flex: 1;
  line-height: 1.3em;
  justify-content: flex-start;
  margin-left: 0;
  margin-right: auto;
}
@media screen and (max-width: 64rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info > .fileName,
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info .fileExtension {
    flex: 0 0 fit-content;
    display: flex;
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info > .fileName {
  font-weight: 600;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info > .fileExtension {
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 21px;
  bottom: 20px;
  margin-top: 2px;
}
@media screen and (max-width: 64rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .file--info > .fileExtension {
    margin-top: 7px;
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem > .ui.button {
  background: transparent;
  flex: 0 0 2rem;
  width: 2rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem > .ui.button i.icon {
  font-size: 1.5rem;
  color: var(--c-secondary);
  height: auto;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem > .ui.button:not(:first-child) {
  margin-left: 10px;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .ui.loader {
  flex: 0 0 2rem;
  position: relative;
  left: auto;
  top: 0;
  right: 0;
  transform: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .ui.loader::before,
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .ui.loader::after {
  position: relative;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem .ui.loader::after {
  left: 0;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem:hover {
  box-shadow: 6px 4px 24px rgba(131, 146, 161, 0.24);
}
@media screen and (max-width: 64rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem {
    flex: 0 0 5.5rem;
    max-height: 5.5rem;
    align-content: center;
  }
}
@media screen and (max-width: 61rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.attachments .fileItem {
    padding: 0.8rem 1.5rem;
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementDetailParagraph .row.call-to-action {
  margin-top: 1rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.cards {
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 -0.5rem;
}
@media (max-width: 48rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.cards {
    flex-wrap: wrap;
    margin: 0;
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card {
  box-shadow: none;
  color: var(--c-grey-dark);
  border-radius: 8px;
  padding: 1.5rem;
  transition: box-shadow 0.3s ease-out;
  box-shadow: 6px 4px 24px rgba(131, 146, 161, 0.24);
}
@media (max-width: 48rem) {
  #app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card {
    width: calc(50% - 1rem);
  }
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > div {
  line-height: 1.2;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card strong {
  color: var(--c-primary);
  font-size: 0.875rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content {
  border-top: none;
  position: relative;
  display: flex;
  flex-direction: column;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content:not(:first-child)::before {
  content: "";
  position: absolute;
  background: var(--c-grey-light);
  height: 1px;
  top: 0;
  left: 5%;
  width: 90%;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .header > a {
  color: var(--c-primary);
  font-weight: bold;
  font-size: 18px;
  line-height: 1.32em;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .sub.header {
  text-transform: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .ui.sub.content {
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 1.4em;
  margin-top: 5px;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .description {
  font-family: var(--primary-font);
  font-size: 14px;
  line-height: 1.4em;
  color: var(--c-grey-norm);
  margin-bottom: 2rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .cta {
  align-self: flex-end;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .cta > a {
  color: var(--c-secondary);
  font-family: var(--primary-font);
  font-weight: bold;
  text-decoration: underline;
}
#app .announcementDetail__content .announcementDetailSectionBody .announcementCardsParagraph .ui.card > .content .cta > i.icon {
  color: var(--c-secondary);
}
#app .announcementDetail__content .announcementDetailSectionBody .otherInfoAndContacts {
  margin-top: 4rem;
}
#app .announcementDetail__content .announcementDetailSectionBody .otherInfoAndContacts .announcementDetailParagraph:not(:first-child) {
  margin: 2.5rem 0 0 0;
}
#app .announcementDetail__content .announcementDetailSectionBody .otherInfoAndContacts .announcementDetailParagraph > .row {
  border-bottom: none;
}
#app .announcementEdit__content {
  font-family: var(--primary-font);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
#app .announcementEdit__content .announcementEditSectionBody {
  padding: 2rem;
  max-width: 1700px;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
}
#app .announcementEdit__content .announcementEditSectionBody .ui.form input[type="text"] {
  font-family: var(--primary-font);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit {
  position: relative;
  width: 100%;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit > .p-title {
  position: relative;
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit > .p-title::after {
  content: "";
  width: calc(100% - 2rem);
  height: 100%;
  position: absolute;
  top: 0;
  left: 1rem;
  border-bottom: 1px solid var(--c-grey-light);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit > .p-title > h1 {
  font-family: var(--primary-font);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row + .p-title {
  margin-top: 3rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column .MuiFormControl-root.MuiTextField-root .MuiButtonBase-root.MuiIconButton-root {
  color: var(--c-secondary);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column label {
  font-size: 0.875rem;
  line-height: 17px;
  font-family: var(--primary-font);
  margin-bottom: 3px;
  padding-left: 0.5rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div .field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div .field div[role="combobox"],
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div .field div[role="listbox"] {
  margin: 0 0 0.35rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div .field label {
  color: var(--c-grey-mid);
  font-size: 1rem;
  line-height: 1.214em;
  position: absolute;
  z-index: 2;
  top: 2rem;
  left: 1rem;
  margin: 0 0 0 0;
  width: calc(100% - 1.3rem);
  pointer-events: none;
  transition: all 0.3s ease-out;
  padding-left: 0;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div .field .selection.dropdown:not([role="listbox"]) {
  padding: 0.19345238em 1.975em 0.19345238em 0.3125em;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div.filled .field label {
  top: 0;
  left: 0.5rem;
  color: var(--c-grey-dark);
  font-size: 0.875rem;
  line-height: 17px;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column div.filled .field.error label {
  color: var(--c-error);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column textarea {
  font-family: var(--primary-font);
  font-size: 1rem;
  margin-bottom: 0.35rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row .column textarea ~ span {
  font-size: 0.75rem;
  color: var(--c-error);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row > .column {
  flex: 0 0 100%;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row div#editor {
  width: 100%;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row div#editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  color: var(--c-grey-mid);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row div#editor .ck.ck-editor__main > .ck-editor__editable.ck-focused {
  color: var(--c-grey-dark);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row div#editor .ck.ck-editor .ck.ck-editor__main .ck-editor__editable {
  width: 100%;
  max-width: 100%;
  height: 9rem;
  font-size: 1rem;
  line-height: 1.5em;
  word-break: break-word;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row div#editor.taller .ck.ck-editor .ck.ck-editor__main .ck-editor__editable {
  height: 18rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .dropzone-area {
  padding: 1.5rem 3rem;
  border: 1px dashed var(--c-grey-light);
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .dropzone-area .left-side {
  display: flex;
  align-items: center;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .dropzone-area .left-side i.icon {
  font-size: 1.5rem;
  color: var(--c-secondary);
  margin-right: 13px;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .dropzone-area .left-side .upload--text p {
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .dropzone-area .left-side .upload--text p:last-child {
  font-size: 0.875rem;
  color: var(--c-grey-mid);
  margin-top: 3px;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .dropzone-area button.ui.button--primary--negative {
  border-radius: 4px;
  padding: 15px 1.5rem !important;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .upload--error {
  font-size: 0.75rem;
  margin-left: 1rem;
  color: var(--c-error);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row input[type="file"] {
  width: 100%;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded {
  margin-top: 20px;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded h3,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded h3 {
  font-family: var(--primary-font);
  font-weight: 1rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__list,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 1rem;
  background: var(--c-background);
  border-radius: 5px;
  width: 98%;
  flex: 0 0 2.5rem;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item:not(:first-child),
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item:not(:first-child) {
  margin-top: 5px;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item h4,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item h4 {
  margin: 0;
  font-size: 1rem;
  line-height: 19px;
  font-family: var(--primary-font);
  font-weight: normal;
  display: flex;
  align-items: inherit;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item .upload__left,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item .upload__left,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item .upload__right,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item .upload__right {
  display: flex;
  align-items: inherit;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item .upload__left i.icon,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item .upload__left i.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  padding: 0;
  position: relative;
  line-height: 1em;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__item .upload__left i.icon:before,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__item .upload__left i.icon:before {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__delete,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__delete {
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__delete::after,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__delete::after,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__delete::before,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__delete::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background: var(--c-secondary);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1px;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__delete::after,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__delete::after {
  transform: rotate(135deg);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__delete::before,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__delete::before {
  transform: rotate(45deg);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__dowmload,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__dowmload,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__preview,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__preview {
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  color: var(--c-secondary);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__dowmload > i.icon,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__dowmload > i.icon,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .toBeUploaded .upload__right .upload__preview > i.icon,
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__preview > i.icon {
  margin: 0;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .one.column.row .alreadyUploaded .upload__right .upload__remove {
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  color: var(--c-secondary);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .two.column.row > .column {
  flex: 0 0 calc(100% / 2);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .three.column.row > .column {
  flex: 0 0 calc(100% / 3);
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row.call-to-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row.call-to-action .column:first-child {
  display: flex;
  flex-direction: row;
}
#app .announcementEdit__content .announcementEditSectionBody .form__AnnouncementEdit .row.call-to-action button > i.icon {
  margin-right: 8px !important;
}
#app .announcementEdit__content .announcementEditSectionBody .row.archive {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
#app .announcementEdit__content .announcementEditSectionBody .row.archive .ui.button:not(:last-child) {
  margin-right: 15px;
}
#app .announcementEdit__content .reloader {
  position: relative;
  width: 100%;
  min-height: calc(100vh - (6.25rem + 146px));
  display: flex;
  align-items: center;
  justify-content: center;
}
#app .invalid-field {
  border: 1px solid #E0B4B4;
  background-color: #FFF6F6;
}
#app .invalid-field-error {
  color: #eb5757;
}
#app .invalid-field-label {
  color: #9f3a38;
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm {
  position: relative;
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  border: 1px solid;
  z-index: 10;
  font-weight: 600;
  font-family: var(--primary-font);
  transition: all 0.3s linear;
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm.yes {
  border-color: var(--c-success);
  color: var(--c-success);
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm.no {
  border-color: var(--c-error);
  color: var(--c-error);
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm:last-child {
  margin-left: 15px;
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm:hover {
  transition: all 0.3s linear;
  color: var(--c-white);
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm:hover.yes {
  background: var(--c-success);
}
.MuiDialog-root .MuiButton-textPrimary.button--confirm:hover.no {
  background: var(--c-error);
}
.MuiDialog-root .css-hlj6pa-MuiDialogActions-root {
  padding: 1rem 24px;
}
.MuiDialog-root .MuiPickersDay-daySelected {
  background: var(--c-secondary) !important;
}
.MuiDialog-root .MuiPickersDay-daySelected:hover {
  background: var(--c-primary) !important;
  color: var(--c-white) !important;
}
.MuiDialog-root .MuiPickersDay-current {
  color: var(--c-primary) !important;
}
#app .contact-container {
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0 7rem;
}
#app .contact-container .contact-form-wrapper {
  max-width: 500px;
  background-color: var(--c-white);
  padding: 3rem 3rem 5rem;
  box-shadow: 1px 2px 10px 2px var(--c-grey-light);
  z-index: 10;
}
@media screen and (min-width: 105rem) {
  #app .contact-container .contact-form-wrapper {
    max-width: 600px;
    padding: 3rem 3rem 8rem;
  }
}
#app .contact-container .contact-form-wrapper h1 {
  margin: 0 1.5rem 1.5rem 1.5rem;
  font-size: 2.5rem;
}
#app .contact-container .contact-form-wrapper form#contact-form {
  margin: 0;
  padding: 1rem 3rem;
}
#app .contact-container .contact-form-wrapper form#contact-form .field:not(:first-child) {
  margin-top: 2rem;
}
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.input > *,
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.selection {
  border-top: none;
  border-left: none;
  border-right: none;
}
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.input > *:focus,
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.selection.active .menu {
  border-color: var(--c-secondary);
}
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.input > *:focus .selected.item,
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.selection.active .menu .selected.item,
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.input > *:focus .item:hover,
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.selection.active .menu .item:hover {
  background-color: rgb(var(--c-secondary-rgb), 0.1);
}
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.button {
  width: 100%;
  margin-bottom: 2rem;
}
#app .contact-container .contact-form-wrapper form#contact-form .field .ui.pointing.label {
  display: none;
}
