#app {
  .contact-container {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 5rem 0 7rem;

    .contact-form-wrapper {
      max-width: 500px;
      background-color: @c-white;
      padding: 3rem 3rem 5rem;
      box-shadow: 1px 2px 10px 2px @c-grey-light;
      z-index: 10;

      @media screen and (min-width: @bp-2xl-mx) {
        max-width: 600px;
        padding: 3rem 3rem 8rem;
      }

      h1 {
        margin: 0 1.5rem 1.5rem 1.5rem;
        font-size: 2.5rem;
      }

      form#contact-form {
        margin: 0;
        padding: 1rem 3rem;

        .field {
          &:not(:first-child) {
            margin-top: 2rem;
          }

          .ui.input > *,
          .ui.selection {
            border-top: none;
            border-left: none;
            border-right: none;
          }

          .ui.input > *:focus,
          .ui.selection.active .menu {
            border-color: @c-secondary;

            .selected.item,
            .item:hover {
              background-color: rgb(@c-secondary-rgb, 0.1);
            }
          }

          .ui.button {
            width: 100%;
            margin-bottom: 2rem;
          }

          .ui.pointing.label {
            display: none;
          }
        }
      }
    }
  }
}
