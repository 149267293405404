//ANIMATION
@keyframes on-hover-button {
  0% {
    background: linear-gradient(
      @gradient-deg,
      @c-secondary-light 0%,
      @c-secondary-dark 100%
    );
    background-size: 200% 200%;
  }
  100% {
    background-position: 100% 100%;
  }
}

// DEFAULT
button.ui.button {
  background: linear-gradient(
    @gradient-deg,
    @c-secondary-light 0%,
    @c-secondary-dark 100%
  );
  border-radius: 4px;
  color: @c-white;
  font-family: @primary-font;
  font-weight: 600;
  font-size: 1rem;
  padding: 0 0;
  margin-right: 0;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 15px 2rem;
    background: transparent;
    border-radius: inherit;
    border: none;

    i.icon:only-child {
      margin: 0 0.5rem 0 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(
      @gradient-deg,
      @c-secondary-light 0%,
      @c-secondary-dark 100%
    );
    color: @c-white;
    > div {
      animation: on-hover-button 0.2s linear;
      background: linear-gradient(
        @gradient-deg,
        @c-secondary-light 0%,
        @c-secondary-dark 100%
      );
      pointer-events: none;
    }
  }
  &.disabled {
    color: @c-white;
    background: linear-gradient(@gradient-deg, @c-secondary-light 0%, @c-secondary 100%) !important;
    cursor: not-allowed;
    opacity: 0.75 !important;
  }
}

.ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin: 0 0 0 0;
}

// PRIMARY
button.ui.button.primary,
button.ui.button--primary,
.button--primary button.ui.button {
  border-radius: 4px;
  padding: 15px 2rem;
  margin: 0;
  background: linear-gradient(@gradient-deg, @c-secondary-light 0%, @c-secondary 100%);
  color: @c-white;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2 linear;
  &:hover,
  &:focus,
  &:active {
    color: @c-white;
    background: linear-gradient(@gradient-deg, @c-secondary-light 0%, @c-secondary 100%);
  }
}

// NEGATIVE
button.ui.button--primary--negative,
.button--primary--negative button.ui.button {
  border-radius: 4px;
  background: transparent;
  color: @c-primary;
  border-color: @c-primary;
  border: 2px solid @c-primary;
  padding: 15px 2rem !important;
  &:hover,
  &:focus,
  &:active {
    background: @c-primary;
    color: @c-white;
  }
}

// UNDERLINE
button.ui.button--underline,
a.ui.button--underline {
  position: relative;
  background: transparent;
  padding: 0 !important;
  margin: 0;
  display: flex;
  align-items: center;
  height: fit-content;
  > div,
  i.icon {
    background: transparent !important;
    width: fit-content;
  }
  > .button--text,
  i.icon {
    color: @c-secondary;
    transition: all 0.3s ease-out;
  }
  > .button--text {
    position: relative;
    padding: 3px 5px;
    line-height: 1.2em;
    &::after {
      content: "";
      background: transparent;
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      right: 5px;
      border-bottom: 1px solid @c-secondary;
      transition: all 0.3s ease-out;
      transform-origin: bottom right;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    > div {
      background: transparent;
    }
    > .button--text,
    i.icon {
      color: @c-secondary;
      transition: all 0.3s ease-out;
      &::after {
        border-color: @c-secondary;
        transition: all 0.3s ease-out;
      }
    }
  }

  &:hover,
  &:active {
    > .button--text,
    i.icon {
      &::after {
        width: calc(100% - 10px);
      }
    }
  }

  &:disabled {
    color: @c-white;
    background-color: @c-secondary-dark;
    cursor: not-allowed;
  }
}

// UNDERLINE NEGATIVE
button.ui.button--underline--negative,
a.ui.button--underline--negative {
  text-decoration: underline;
  background: transparent;
  padding: 0.9rem 0.5rem !important;
  color: @c-primary;
  font-weight: 500;
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
}

// RESET
button.ui.button--reset {
  &:hover {
    > .button--text,
    i.icon {
      color: @c-primary;
      &::after {
        border-color: @c-primary;
      }
    }
    > i.icon {
      transform: rotate(360deg);
    }
  }
}

// SUCCESS
button.ui.button--success {
  background: @c-success;
  color: @c-white;
}

// DARK
button.ui.button--dark,
.button--dark button.ui.button {
  background: #000;
  color: @c-white;
}

// GRID BUTTONS
.ui.buttons .button:first-child {
  border: 1px solid @c-primary;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ui.buttons .ui.button.primary {
  color: @c-white;
  background: @c-primary;
}

button.ui.button.button--grid {
  color: @c-secondary-dark;
  padding: 1.125rem 2rem;
  background: transparent;
  border: 1px solid @c-primary;
  border-radius: 5px;

  > i.icon {
    font-size: @font-24;
    margin-right: 1rem !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: @c-secondary;
    background-color: rgba(@c-primary-rgb, 10%);
  }
}

.ui.button.button--grid {
  font-size: @font-16 !important;
  font-weight: 500;
  line-height: 1.2em;
  text-align: left;
  display: flex;
}

//LOGOUT
button.ui.logout--button,
.logout--button button.ui.button {
  background: transparent;
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }

  i {
    color: @c-secondary;
  }
}

//LOGIN
.login--button {
  width: 100%;
  background: linear-gradient(
    @gradient-deg,
    @c-secondary-light 0%,
    @c-secondary-dark 100%
  );
  border-radius: 4px;
  cursor: pointer;
  > .ui.button {
    padding: 18px 0;
    background: transparent;
    width: 100%;
    height: 100%;
  }
  &:hover,
  &:focus,
  &:active {
    animation: on-hover-button 0.2s linear;
    background: linear-gradient(
      @gradient-deg,
      @c-secondary-light 0%,
      @c-secondary-dark 100%
    );
    > .ui.button {
      background: transparent;
    }
  }
}
