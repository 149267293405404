#app {
  .password-framework {
    z-index: 5;
    color: var(--c-primary);
    max-width: 90vw;
    background: var(--c-white);
    padding: 2rem;
    opacity: 1;
    border-radius: 4px;
    filter: drop-shadow(6px 4px 24px rgba(131, 146, 161, 0.24));
    .password-framework__container {
      background: transparent;
      .ui.segment {
        background: transparent;
        border: none;
        box-shadow: unset;
        padding: 0;
      }
    }
    .ui.grid {
      .row.token--error--message {
        padding: 0;
        margin-bottom: 1rem;
      }
    }
    img {
      margin-bottom: 1rem;
    }
    button.ui.button {
      div {
        pointer-events: all;
        a {
          color: var(--c-white);
        }
      }
      &.button--submit,
      &.button--to-dashboard {
        margin-top: 1.5rem;
      }
    }
    @media screen and (max-width: 48rem) {
      form.ui.form .grid > .row > .column {
        &:not(:first-child) {
          margin-top: 1rem;
        }
      }
    }
  }
}
