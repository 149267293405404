#app {
  .elencoBandilayout__container {
    width: 100%;
    height: 100%;
    padding-top: @height-topbar;
    min-height: 100vh;
    background: @c-white no-repeat center center;
    background-size: cover;
    position: relative;

    @media screen and (max-width: @bp-md-mx) {
      margin: 6.25rem 0 0 0;
    }

    .wave_bg {
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      z-index: 0;

      @media screen and (min-width: @bp-xl-mx) {
      }
    }

    .scroll__container {
      z-index: 10;
      & > * {
        z-index: inherit;
      }
      > .subHeader {
        width: 100%;
        padding: 3rem 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 10;

        & > * {
          z-index: inherit;
        }

        @media (max-width: @bp-md-mx) {
          padding: 2rem;
        }

        > h1 {
          color: @c-primary;
          font-size: 3rem;
          line-height: 1.2em;
          display: flex;
          justify-content: center;
          text-align: center;
          max-width: 40rem;

          @media (max-width: @bp-md-mx) {
            font-size: @font-28;
          }
        }
        > h4 {
          margin-top: 0;
          font-family: @primary-font;
          font-size: 1.25rem;
          line-height: 1.4em;
          font-weight: 400;
          text-align: center;
        }
      }
      .announcementMain__container {
        width: 100%;
        position: relative;
        display: flex;

        @media (max-width: @bp-md-mx) {
          flex-direction: column;
        }

        .announcementGrid__container {
          width: 100%;
          padding: 0 4rem;

          @media (max-width: @bp-md-mx) {
            padding: 2rem 1rem 0;
          }
        }
      }
    }
  }
}
