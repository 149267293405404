.fc.fc-theme-standard {
  .fc-toolbar-title {
    font-size: @font-28;
    font-weight: 500;

    @media screen and (max-width: @bp-md-mx) {
      margin-bottom: 1rem;
    }
  }

  .fc-header-toolbar {
    @media screen and (max-width: @bp-md-mx) {
      display: flex;
      flex-direction: column;
    }
  }

  .fc-col-header-cell-cushion {
    color: #000;
    font-weight: 500;
  }

  .fc-bg-event {
    background-color: @c-success !important;
    opacity: .75;
  }

  .fc-v-event {
    background: @c-primary-light;
    border-color: @c-primary-light;
    border-radius: 0;
    box-shadow: 0 0 0 1px @c-primary;
  }
}