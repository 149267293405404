.ui.pagination.menu {
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: @primary-font;
  font-size: @font-14;

  .item {
    margin-left: 0.5rem;
    border-radius: 2px;
    display: inline-block;
    padding: 0;
    background: @c-white;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    min-width: unset;

    &:last-child {
      border-radius: 2px;
    }

    &:before {
      content: none;
    }

    &:hover,
    &.active {
      background: @c-secondary;
      color: @c-white;
      font-weight: 700;
      padding-top: 0;
    }
  }
}