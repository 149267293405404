.tooltip {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  &--right {
    justify-content: flex-start;

    > img {
      margin-inline-start: 0.5em;
    }
  }

  &--left {
    justify-content: flex-start;

    > img {
      margin-inline-end: 0.5em;
    }
  }

  > span {
    > span {
      .ui.header:first-child {
        margin-top: 0;
      }
      display: flex;
      align-items: center;
    }
  }
}

//MUI-TOOLTIPS

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background-color: @c-secondary;
    color: @c-white;
    box-shadow: 1px 1px 2px 1px rgba(97, 97, 97, 0.9);
    font-size: 10px;
    font-family: @primary-font;
    .MuiTooltip-arrow {
      color: @c-secondary;
    }
  }
  &.isDisabled {
    .MuiTooltip-tooltip {
      background: @c-error;
      .MuiTooltip-arrow {
        color: @c-error;
      }
    }
  }
  &.isTooltip {
    .MuiTooltip-tooltip {
      background: @c-primary;
      .MuiTooltip-arrow {
        color: @c-primary;
      }
    }
  }
  &.isGrey {
    .MuiTooltip-tooltip {
      background: @c-grey-dark;
      .MuiTooltip-arrow {
        color: @c-grey-dark;
      }
    }
  }
}
