/* ------ HEADER ------ */
#app .sidebar.sidebar--left + .topbar {
  padding-left: 7rem;

  @media (max-width: @bp-md-mx) {
    padding: 1rem;
  }
}

#app .topbar {
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1.75rem 3.5rem;
  border-bottom: 1px solid @c-grey-light;
  border-radius: 0;
  background: @c-white;
  z-index: 999;
  height: @height-topbar;

  @media screen and (max-width: @bp-md-mx) {
    padding: 1rem 2rem;
  }

  .item {
    padding: 0;
    button.ui.button {
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;

      @media screen and (max-width: @bp-md-mx) {
        height: 2.75rem;
        width: 2.75rem;
        font-size: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .left.menu > a.item {
    @media screen and (max-width: @bp-md-mx) {
      margin-left: 0;
    }

    &:not(:first-child) {
      margin-left: 2rem;

      .item {
        color: @c-primary;
        font-family: @primary-font;
        font-weight: 600;
        font-size: 1rem;
        padding: 5px 0;

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          border-bottom: 1px solid @c-primary;
          left: 0;
          bottom: 0;
          background: transparent;
          transition: width 0.3s linear;
        }
      }

      &.active,
      &:hover {
        .item {
          color: @c-secondary;
          transition: width 0.3s linear;
          &::after {
            width: 100%;
            border-color: @c-secondary;
          }
        }
      }
    }

    &:first-child {
      margin-left: 1rem;

      @media screen and (max-width: @bp-md-mx) {
        margin-left: 0;
      }
    }

    .ui.image {
      width: 14rem;
      height: 3.6rem;
    }
  }

  .right.menu {
    color: @c-primary;
    font-family: @primary-font;
    font-size: 1rem;
    font-weight: 600;

    > .sign__out {
      display: flex;
      align-items: center;
      cursor: default;
      text-align: right;

      @media screen and (max-width: @bp-md-mx) {
        font-size: 0;
      }

      > a {
        margin-left: 10px;

        @media screen and (max-width: @bp-md-mx) {
          margin-left: 0;
        }

        i {
          @media screen and (max-width: @bp-md-mx) {
            font-size: 1.5rem;
          }
        }
      }
    }

    @media screen and (max-width: @bp-md-mx) {
      margin-left: 0.75rem;
    }
  }
}

/* SINGLE-PAGE HEADER STYLE */

#app {
  .ui.header {
    color: @c-primary;
    font-family: inherit;
  }
  .ui.dividing.header {
    color: @c-primary;
    font-size: @font-32;
    font-weight: 600;
    border-bottom: none;
    margin: 0;
    padding: 2rem 4rem;
    @media screen and (max-width: @bp-md-mx) {
      padding: 2rem 2rem;
    }
    &.userHeader {
      .ui.grid {
        > .row:not(.first-row) {
          .column {
            margin-bottom: 1rem;
            @media screen and (max-width: 1100px) {
              flex: 0 0 calc(50% - 0.5rem);
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
            @media screen and (max-width: 800px) {
              flex: 0 0 100%;
              &:not(:last-child) {
                padding-bottom: 1rem;
              }
            }
          }
          .button-column {
            flex: 0 0 fit-content;
            > .field {
              margin-left: 0;
            }
          }
        }
      }
    }

    .ui.grid {
      margin: 0;
      align-items: center;
      .column {
        padding: 0;
      }
    }

    .ui.grid {
      > .row {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 0;
        position: relative;
        width: 100%;
        padding: 2rem 0;
        &.first-row {
          border-bottom: 1px solid @c-grey-light;
          justify-content: space-between;
          > .column {
            flex: 0 0 fit-content;
            @media screen and (max-width: @bp-md-mx) {
              flex: 0 0 100%;
              &:not(:last-child) {
                padding-bottom: 1rem;
              }
            }
          }
        }
        &:not(.first-row) {
          > .column {
            margin-bottom: 1rem;
            &:not(.button-column) {
              margin-right: 1rem;
            }
            @media screen and (max-width: 920px) {
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
            @media screen and (max-width: @bp-xmd-mx) {
              flex: 0 0 100%;
              padding-bottom: 1rem;
            }
          }
        }
        > .column {
          flex: 0 0 25%;
          @media screen and (max-width: 920px) {
            flex: 0 0 calc(50% - 0.5rem);
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
          &[class$="buttons"] {
            height: 50px;
          }
          @media screen and (max-width: @bp-md-mx) {
            &[class$="buttons"] {
              height: fit-content;
            }
          }
        }
        .button-column {
          display: flex;
          align-items: center;
          flex: 0 0 fit-content;
          margin-right: 0;
          margin-bottom: 0;
          min-height: 42px;
          @media screen and (max-width: @bp-xmd-mx) {
            margin-top: 1rem;
          }
          .button--reset {
            margin-left: 1rem;
          }
          .field {
            @media screen and (max-width: @bp-xmd-mx) {
              margin-left: 0;
            }
          }
        }
        .field {
          margin: 0;
          display: flex;
        }
      }
    }
  }
}
