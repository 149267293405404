.ui.page.modals {
  background-color: rgba(0, 0, 0, 0.3);

  .modal {
    box-shadow: none;

    .close.icon {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 400;
      top: 1.7rem;
      right: .8rem;
      color: @c-primary;
    }

    > div.header {
      font-weight: 500;
      font-size: @font-24;
      background-color: transparent;
      padding: 1.5rem 2rem;
      h3 {
        color: @c-primary;
      }
    }

    .content {
      padding: 2rem;

      h3.header {
        font-size: @font-24;
        font-weight: 500;
      }
    }

    > div.noPaddingTop.content {
      padding-top: 0;
    }

    .actions {
      button {
        margin: 0 0 0 1rem;
      }
    }
  }

  //Tootip modal
  figure.table {
    margin: 0;
    table {
      border-collapse: collapse;
      tr {
        border: 1px solid @c-grey-mid;
        > td {
          &:first-of-type {
            border-right: 1px solid @c-grey-mid;
            font-weight: 600;
          }
        }
      }
      td {
        padding: 10px;
      }
    }
  }
}
